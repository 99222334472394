import upperFirst from 'lodash/upperFirst'

const getUserGenders = {
  method: 'get',
  path: '/personal/:username/genders',
  transformData: (data) =>
    data.results.map(({ id, gender }) => ({
      id,
      gender: upperFirst(gender),
    })),
}

export default getUserGenders
