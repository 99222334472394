import get from 'lodash/get'

import { USER_ROLE_ENTITY } from '_v3/user/constants'
import { USER_ENTITY } from '_v3/user/constants'
import userSchema from '_v3/user/schemas/userSchema'

import { useEntityService } from 'hooks/useEntityService'
import { useHTTPService } from 'hooks/useHTTPService'

const loadCurrentUserRequest = () => ({
  method: 'get',
  path: '/auth/user',
  schema: userSchema,
})

export const useUserService = () => {
  const { getEntities, getEntity, mergeEntities } = useEntityService()
  const { sendRequest } = useHTTPService()

  const getUserRole = (roleSlug) => getEntity(USER_ROLE_ENTITY, roleSlug)

  const loadCurrentUser = async () => {
    const { entities, result: userId } = await sendRequest(
      loadCurrentUserRequest()
    )
    await mergeEntities(entities)
    return getUser(userId)
  }

  const getUsers = () => getEntities(USER_ENTITY)

  const getUser = (userId, path) => {
    const user = getEntity(USER_ENTITY, userId)
    return path ? get(user, path) : user
  }

  const getUserPhoto = (userId) => getEntity(USER_ENTITY, userId)

  const getUserRoles = (userId) => {
    const user = getUser(userId)
    if (!user) return false
    return user.roleIds.map(getUserRole)
  }

  const hasCareTeams = (userId) => getUser(userId).careTeamIds.length > 0

  const _hasRole = (userId, roleSlug) => {
    const user = getUser(userId)
    if (!user) return false
    return user.roleIds
      .map(getUserRole)
      .some(({ slug }) => slug.indexOf(roleSlug) > -1)
  }

  const isAdmin = (userId) => _hasRole(userId, 'administrator')
  const isManager = (userId) => _hasRole(userId, 'manager')
  const isFinancialAdmin = (userId) => _hasRole(userId, 'financial')
  const isEducationAdmin = (userId) => _hasRole(userId, 'education')
  const isSurveyAdmin = (userId) => _hasRole(userId, 'survey')
  const isPhysician = (userId) => _hasRole(userId, 'physician')
  const isAdvisor = (userId) => _hasRole(userId, 'advisor')
  const isCounselor = (userId) => _hasRole(userId, 'counselor')
  const isPatient = (userId) => _hasRole(userId, 'patient')
  const isNurse = (userId) => _hasRole(userId, 'nurse')
  const isInstituteNurse = (userId) => _hasRole(userId, 'institute_nurse')
  const isCoordinator = (userId) => _hasRole(userId, 'coordinator')

  return {
    loadCurrentUser,
    getUsers,
    getUser,
    getUserPhoto,
    getUserRoles,
    hasCareTeams,
    isAdmin,
    isManager,
    isFinancialAdmin,
    isEducationAdmin,
    isSurveyAdmin,
    isPhysician,
    isAdvisor,
    isCounselor,
    isPatient,
    isNurse,
    isInstituteNurse,
    isCoordinator,
  }
}
