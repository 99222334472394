const updateEvent = {
  method: 'patch',
  path: '/engagement/:careTeamSlug/event/:eventId',
  transformBody: ({
    id,
    occurred,
    patientId: profile,
    severityId: severity,
    escalationId: escalation,
    description,
    engagements,
    isResolved: resolved,
    completed,
    program,
  }) => {
    return {
      id,
      program,
      occurred,
      profile,
      severity,
      escalation,
      description,
      engagements,
      resolved,
      completed,
    }
  },
}

export default updateEvent
