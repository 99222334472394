import dayjs from 'dayjs'

const parseOccurred = (val) =>
  typeof val === 'string' ? val : dayjs(val).utc().format()

const createEvent = {
  method: 'post',
  path: '/engagement/:careTeamSlug/event',
  transformBody: ({
    patientId: profile,
    severityId: severity,
    escalationId: escalation,
    description,
    occurred = new Date(),
  }) => ({
    profile,
    severity,
    escalation,
    description,
    occurred: parseOccurred(occurred),
    engagements: [],
    notes: [],
    messages: [],
  }),
}

export default createEvent
