import React from 'react'

import Box from '_v3/core/views/Box'

const NavBar = ({ children }) => (
  <Box
    borderBottomColor="green1"
    borderBottomWidth={5}
    backgroundGradient="lightGray"
    position="fixed"
    top={0}
    left={0}
    right={0}
    zIndex={10}
  >
    {children}
  </Box>
)

export default NavBar
