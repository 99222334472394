import React from 'react'
import { navigate } from '@reach/router'
import { Position } from '@blueprintjs/core'

import NavBarItem from '_v3/core/views/NavBarItem'
import { useUserService } from 'hooks/useUserService'
import { useSessionService } from 'hooks/useSessionService'

const ReportsNavBarItem = ({ location }) => {
  const { userId } = useSessionService()
  const {
    isAdmin,
    isManager,
    isAdvisor,
    isCoordinator,
    isCounselor,
    isFinancialAdmin,
  } = useUserService()

  const dropdownItems = []

  if (
    isAdmin(userId) ||
    isManager(userId) ||
    isAdvisor(userId) ||
    isCoordinator(userId) ||
    isCounselor(userId)
  )
    dropdownItems.push({
      label: 'Accumulated Time',
      leftIcon: 'clock-o',
      isActive: !!location.pathname.match('/reports/accumulated-time'),
      onClick: () => navigate('/reports/accumulated-time'),
    })

  if (isFinancialAdmin(userId))
    dropdownItems.push({
      label: 'Pending Billable Patients',
      leftIcon: 'group',
      isActive: !!location.pathname.match('/reports/billable-patients'),
      onClick: () => navigate('/reports/billable-patients'),
    })

  if (
    isAdmin(userId) ||
    isManager(userId) ||
    isAdvisor(userId) ||
    isCoordinator(userId) ||
    isCounselor(userId)
  )
    dropdownItems.push({
      label: 'Advisor Performance',
      leftIcon: 'dashboard',
      isActive: !!location.pathname.match('/reports/advisor-performance'),
      onClick: () => navigate('/reports/advisor-performance'),
    })

  if (
    isAdmin(userId) ||
    isManager(userId) ||
    isAdvisor(userId) ||
    isCoordinator(userId) ||
    isCounselor(userId)
  )
    dropdownItems.push({
      label: 'Advisor Efficiency',
      leftIcon: 'bolt',
      isActive: !!location.pathname.match('/reports/advisor-efficiency'),
      onClick: () => navigate('/reports/advisor-efficiency'),
    })

  if (isFinancialAdmin(userId))
    dropdownItems.push({
      label: 'Daily Billable',
      leftIcon: 'credit-card',
      isActive: !!location.pathname.match('/reports/daily-billable'),
      onClick: () => navigate('/reports/daily-billable'),
    })

  return (
    dropdownItems.length > 0 && (
      <NavBarItem
        label="Reports"
        leftIcon="file"
        dropdownItems={dropdownItems}
        dropdownPlacement={Position.BOTTOM}
      />
    )
  )
}

export default ReportsNavBarItem
