import { camelizeKeys } from 'humps'

const getCareTeamMembers = {
  method: 'get',
  path: '/care_team/:slug/member',
  transformData: (data) => {
    const results = data.results || []
    const members = results.map(
      ({
        id,
        username,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        mobile_number: mobileNumber,
        initials,
        email,
        profile_type: roles,
        disabled,
        ...rest
      }) => {
        const _roles = roles
          ? roles.map(({ profile_type }) => profile_type)
          : []

        return {
          ...camelizeKeys(rest),
          id,
          username,
          firstName,
          lastName,
          phoneNumber,
          mobileNumber,
          email,
          roles: _roles,
          initials,
          fullName: `${lastName}, ${firstName}`,
          isManager: _roles.indexOf('manager') !== -1,
          isCoordinator: _roles.indexOf('coordinator') !== -1,
          isPhysician: _roles.indexOf('physician') !== -1,
          isNurse: _roles.indexOf('nurse') !== -1,
          isInstituteNurse: _roles.indexOf('institute_nurse') !== -1,
          isAdvisor: _roles.indexOf('advisor') !== -1,
          isCounselor: _roles.indexOf('counselor') !== -1,
          isPatient: _roles.indexOf('patient') !== -1,
          disabled,
        }
      }
    )
    return {
      ...data,
      all: members,
      managers: members.filter(({ isManager }) => isManager),
      coordinators: members.filter(({ isCoordinator }) => isCoordinator),
      physicians: members.filter(({ isPhysician }) => isPhysician),
      nurses: members.filter(({ isNurse }) => isNurse),
      instituteNurses: members.filter(
        ({ isInstituteNurse }) => isInstituteNurse
      ),
      advisors: members.filter(({ isAdvisor }) => isAdvisor),
      counselors: members.filter(({ isCounselor }) => isCounselor),
      patients: members.filter(({ isPatient }) => isPatient),
    }
  },
}

export default getCareTeamMembers
