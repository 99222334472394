const getAlternateContacts = {
  method: 'get',
  path: '/personal/:username/relation',
  redispatchOn: [
    'createAlternateContact',
    'updateAlternateContact',
    'deleteAlternateContact',
  ],
}

export default getAlternateContacts
