import { useMachine } from '@xstate/react'
import { channelMachine } from '../Machines/channelMachine'

export function useChannel(params) {
  const [, send] = useMachine(
    channelMachine
      .withContext({
        path: params.path,
      })
      .withConfig({
        actions: {
          handleMessage: (_, e) => params.handleMessage(e && e.data),
        },
      })
  )

  return { send }
}
