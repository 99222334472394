import { createElement } from 'react'

import { H1, H2, H3, H4, H5 } from '@blueprintjs/core'

import './Heading.css'

const headingsBySize = {
  xs: H5,
  sm: H4,
  md: H3,
  lg: H2,
  xl: H1,
}

const Heading = ({ size, children }) =>
  createElement(headingsBySize[size], null, children)

export default Heading
