const connectUserDevice = {
  method: 'post',
  path: '/humanapi/:username/connect',
  transformBody: ({ humanId, clientId, sessionToken: session }) => ({
    humanId,
    clientId,
    session,
  }),
}

export default connectUserDevice
