import dayjs from 'dayjs'

const getCalendarEvents = {
  method: 'get',
  path: '/personal/:username/calendar',
  transformData: (data) => {
    return data.results.map((item) => {
      const startDate = dayjs(item.occurred).toDate()
      const endDate = dayjs(startDate).add(30, 'minute').toDate()
      return {
        startDate,
        endDate,
        id: item.id,
        title: item.description,
        resolved: item.resolved,
        completed: item.completed,
      }
    })
  },
}

export default getCalendarEvents
