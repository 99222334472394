import * as R from 'ramda'
import axios from 'axios'
import { normalize } from 'normalizr'

import BadGateway from '_v3/core/errors/BadGateway'
import BadRequest from '_v3/core/errors/BadRequest'
import { parseError } from 'systems/Request'

export const useHTTPService = () => {
  const sendRequest = async ({
    method,
    path,
    params,
    body,
    schema,
    transformBody = R.identity,
    transformData = (data) => {
      return schema ? normalize(data, schema) : data
    },
  }) => {
    for (let i = 1; i <= 4; i++) {
      try {
        const { data } = await axios({
          method,
          url: `${process.env.REACT_APP_API_URL.slice(0, -1)}${path}/`,
          params,
          headers: localStorage.token
            ? { Authorization: `Token ${localStorage.token}` }
            : null,
          data: transformBody(body),
        })
        return transformData(data)
      } catch (error) {
        if (error.response === undefined) throw error

        const { status, data } = error.response

        if (status === 400) {
          const { message, detail, non_field_errors } = data
          throw new BadRequest(
            message ||
              detail ||
              (non_field_errors && non_field_errors[0]) ||
              'Unknown error'
          )
        }

        if (status === 401) {
          throw new BadRequest('Unauthorized')
        }

        // When API returns 404, it means the resource doesn't exists.
        // In that case we need to return null to be able to either dispatch a
        // POST or PUT/PATCH request
        if (status === 404) {
          return error || null
        }

        await new Promise((resolve) => setTimeout(resolve, 2500))

        console.info(
          `[HTTPService] ${method} ${path} failed, retrying (${i})...`
        )

        if (i === 4) {
          console.info(
            `[HTTPService] All retries failed for ${method} ${path}.`
          )

          if (status === 502) throw new BadGateway()

          // if (data === undefined || data === null) throw new EmptyResponse()

          throw error
        }
      }
    }
  }

  return {
    sendRequest: async (params) => {
      try {
        return sendRequest(params)
      } catch (error) {
        const { method, path, params: query, body } = params

        parseError(error, { method, path }, { query, body })

        // We need to rethrow the error to fail cases where we are using `then()`
        throw error
      }
    },
  }
}
