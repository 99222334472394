import upperFirst from 'lodash/upperFirst'

const getEvents = {
  method: 'get',
  path: '/engagement/:careTeamSlug/events',
  transformData: (data) => {
    return {
      ...data,
      results: data.results.map((datum) => ({
        ...datum,
        id: datum.id,
        occurred: datum.occurred,
        patient: {
          id: datum.profile.id,
          username: datum.profile.username,
          photo: datum.photo,
          fullName: `${datum.profile.last_name}, ${datum.profile.first_name}`,
          firstName: datum.profile.first_name,
          lastName: datum.profile.last_name,
        },
        severityId: datum.severity.id,
        escalationId: datum.escalation.id,
        escalationType: upperFirst(datum.escalation.profile_type),
        description: datum.description,
        engagementCount: datum.engagements.length,
        isResolved: datum.resolved,
        engagements: datum.engagements.map(({ owner, ...rest }) => ({
          ...rest,
          owner: {
            id: owner.id,
            fullName: `${owner.last_name}, ${owner.first_name}`,
            roles: owner.profile_type
              ? owner.profile_type.map(({ profile_type }) => profile_type)
              : [],
          },
        })),
      })),
    }
  },
  redispatchOn: [
    'createEvent',
    'updateEvent',
    'createEngagement',
    'updateEngagement',
    'createNote',
    'updateNote',
  ],
}

export default getEvents
