import upperFirst from 'lodash/upperFirst'

import { schema } from 'normalizr'

import { USER_ROLE_ENTITY } from '_v3/user/constants'

const userRoleSchema = new schema.Entity(
  USER_ROLE_ENTITY,
  {},
  {
    processStrategy: ({ id, profile_type }) => ({
      id,
      slug: profile_type,
      name: String(profile_type).split('_').map(upperFirst).join(' '),
    }),
  }
)

export default userRoleSchema
