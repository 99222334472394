import { Path } from 'path-parser'
import { getRequest } from 'systems/Request'

const getPath = (request, params) => {
  let { path } = getRequest(request)
  if (typeof path === 'function') path = path({ params })
  try {
    return decodeURIComponent(Path.createPath(path).build(params))
  } catch (error) {
    throw new Error(
      `Failed to build path for "${path} ${JSON.stringify(params)}": ${
        error.message
      }`
    )
  }
}

export default getPath
