const getCareTeam = {
  method: 'get',
  path: '/care_team/:slug/profile',
  transformData: ({
    id,
    slug,
    institute: instituteId,
    care_team: name,
    visible,
  }) => ({
    id,
    slug,
    instituteId,
    name,
    visible,
  }),
}

export default getCareTeam
