const telecomEvents = {
  outgoing: ({ to, engId } = {}) => ({ type: 'OUTGOING', to, engId }),
  accept: () => 'ACCEPT',
  reject: () => 'REJECT',
  transfer: (engId) => ({ type: 'TRANSFER', engId }),
  hangUp: () => 'HANG_UP',
  retry: () => 'RETRY',
  dismiss: () => 'DISMISS',
}

export default telecomEvents
