import * as R from 'ramda'

const mapIds = R.map(R.prop('value'))

const createCareTeam = {
  method: 'post',
  path: '/institute/:instituteId/care_teams',
  transformBody: ({
    instituteId: institute,
    name: care_team,
    managers = [],
    coordinators = [],
    physicians = [],
    advisors = [],
    nurses = [],
    patients = [],
    visible,
  }) => ({
    institute,
    care_team,
    visible: String(visible),
    member: [
      ...mapIds(managers),
      ...mapIds(coordinators),
      ...mapIds(physicians),
      ...mapIds(advisors),
      ...mapIds(nurses),
      ...mapIds(patients),
    ],
  }),
}

export default createCareTeam
