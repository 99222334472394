const getInstituteAddress = {
  method: 'get',
  path: '/institute/:instituteId/address',
  transformData: ({
    street_1: street1,
    street_2: street2,
    zipcode,
    area,
    city,
    state,
  }) => ({
    street1,
    street2,
    zipcode,
    area,
    city,
    state,
  }),
}

export default getInstituteAddress
