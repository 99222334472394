import { px } from 'themeUtils'

const theme = {
  radii: [0, 5, 10, 15, 20].map(px),

  shadows: [0, 8, 12, 16].map((n) => `0 0 ${n}px rgba(0, 0, 0, .2)`),

  colors: {
    base: '#5791c1',
    white: '#fff',
    black: '#444',
    gray: [
      '#f9f9f9',
      '#f3f3f3',
      '#eee',
      '#ddd',
      '#ccc',
      '#bbb',
      '#999',
      '#777',
    ],
    green: ['#bfd45f'],
  },

  extendedColors: {
    magenta: ['#fff0f6', '#ffadd2', '#eb3498'],
    red: ['#fff1f0', '#ffa39e', '#f5222d'],
    volcano: ['#fff2e8', '#ffbb96', '#fa551d'],
    orange: ['#fff7e6', '#ffd591', '#fa8c16'],
    gold: ['#fffbe6', '#ffe58f', '#faad14'],
    green: ['#f6ffed', '#b7eb8f', '#72cf46'],
    cyan: ['#e6fffb', '#87e8de', '#15c3c3'],
    blue: ['#e6f7ff', '#91d5ff', '#1790ff'],
    geekBlue: ['#f3f6ff', '#adc6ff', '#3054eb'],
    purple: ['#f9f0ff', '#d3adf7', '#8f5bdb'],
  },

  fontFamilies: {
    sans: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
  },

  fontSizes: [0, 11, 13, 14, 18, 24, 32].map(px),

  avatarSizes: [0, 20, 28, 36].map(px),

  spacing: [0, 5, 10, 15, 20, 25, 30, 40, 50, 60].map(px),
}

export default theme
