import React, { useRef } from 'react'
import { useHoverDirty } from 'react-use'
import { Popover, Classes, Menu, MenuItem } from '@blueprintjs/core'

import Row from '_v3/core/views/Row'
import Text from '_v3/core/views/Text'
import Icon from '_v3/core/views/Icon'

import useDevice from 'hooks/useDevice'

const NavBarItem = ({
  label,
  leftIcon,
  leftAddon,
  dropdownItems,
  dropdownPlacement,
  isActive,
  onClick,
}) => {
  const ref = useRef(null)
  const isHovered = useHoverDirty(ref)
  const { isPhone, isTablet } = useDevice()
  const item = dropdownItems && dropdownItems.find((item) => item.isActive)
  const active = Boolean(isActive || item)

  const content = dropdownItems && (
    <Menu className={Classes.ELEVATION_1}>
      {dropdownItems.map(({ label, leftIcon: icon, onClick }) => (
        <MenuItem
          key={label}
          onClick={onClick}
          text={label}
          {...(icon && {
            icon: <Icon name={icon} size="xs" hasFixedWidth={true} />,
          })}
        />
      ))}
    </Menu>
  )

  const trigger = (
    <Row
      props={{ onClick }}
      backgroundColor={active ? 'lightGray1' : null}
      height={50}
      padding={isPhone || isTablet ? 'sm' : 'md'}
      inBetweenSpacing="sm"
      alignItems="center"
      cursor="pointer"
    >
      {leftAddon ||
        (leftIcon && (
          <Icon
            intent={isHovered || active ? 'primary' : 'tertiary'}
            name={leftIcon}
            size={isPhone || isTablet ? 'xs' : 'sm'}
          />
        ))}
      {isPhone ? null : (
        <Text
          intent={isHovered || active ? 'primary' : 'tertiary'}
          size={isTablet ? 'xs' : 'sm'}
        >
          {label}
        </Text>
      )}
      {dropdownItems && (
        <Icon
          name="angle-down"
          intent={isHovered || active ? 'primary' : 'tertiary'}
          size="xs"
        />
      )}
    </Row>
  )

  return !dropdownItems ? (
    <div ref={ref}>{trigger}</div>
  ) : (
    <div ref={ref}>
      <Popover
        content={content}
        position={dropdownPlacement}
        interactionKind="hover"
      >
        {trigger}
      </Popover>
    </div>
  )
}

export default NavBarItem
