import dayjs from 'dayjs'

export const completeSurvey = {
  method: 'post',
  path: '/survey/:username/complete',
  transformBody: (body) => ({
    ...body,
    completed: dayjs().utc().format(),
  }),
}

export default completeSurvey
