import { camelizeKeys } from 'humps'
const getEvent = {
  method: 'get',
  path: '/engagement/:careTeamSlug/event/:eventId',
  transformData: (data) => {
    return {
      id: data.id,
      program: data.program,
      occurred: data.occurred,
      patient: {
        ...camelizeKeys(data.profile),
        id: data.profile.id,
        username: data.profile.username,
        photo: data.photo,
        fullName: `${data.profile.last_name}, ${data.profile.first_name}`,
        firstName: data.profile.first_name,
        lastName: data.profile.last_name,
        disabled: data.profile.disabled,
      },
      severityId: data.severity,
      escalationId: data.escalation,
      description: data.description,
      engagementCount: data.engagements.length,
      isResolved: data.resolved,
      engagements: data.engagements.map(({ owner, ...rest }) => ({
        ...rest,
        owner: {
          id: owner.id,
          fullName: `${owner.last_name}, ${owner.first_name}`,
          roles: owner.profile_type
            ? owner.profile_type.map(({ profile_type }) => profile_type)
            : [],
        },
      })),
      messages: data.messages,
    }
  },
  redispatchOn: [
    'updateEvent',
    'createEngagement',
    'updateEngagement',
    'sendMessagingMessage',
  ],
}

export default getEvent
