import React from 'react'

import 'styled-components/macro'

const Row = ({ justifyContent, alignItems, wrap, children }) => (
  <div
    css={`
      display: flex;
      flex-direction: row;
      justify-content: ${justifyContent};
      align-items: ${alignItems};
      flex-wrap: ${wrap ? 'wrap' : null};
    `}
  >
    {children}
  </div>
)

export default Row
