import { identity } from 'ramda'
import { camelizeKeys, decamelizeKeys } from 'humps'
import axios from 'axios'

import { getBody } from 'systems/Request'
import { getHeaders } from 'systems/Request'
import { getPath } from 'systems/Request'
import { getQuery } from 'systems/Request'
import { getRequest } from 'systems/Request'

const client = axios.create()

export const fetch = (request, type = 'data') => async (params) => {
  const { method, responseType, transformData = identity } = getRequest(request)

  let parsedPath = null
  try {
    parsedPath = getPath(request, params)
  } catch (error) {
    throw error
  }

  const queryParams = getQuery(request, params)
  const response = await client.request({
    method,
    url: `${process.env.REACT_APP_API_URL.slice(0, -1)}${parsedPath}/`,
    params: decamelizeKeys(queryParams),
    headers: getHeaders(),
    data: getBody(request, params),
    responseType,
  })

  const transformed = transformData(response.data)
  return type === 'blob' ? transformed : camelizeKeys(transformed)
}
