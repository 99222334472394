import React, { useMemo } from 'react'
import { useMachine } from '@xstate/react'

import TelecomContext from './TelecomContext'
import telecomMachine from './telecomMachine'

const TelecomContextController = ({ children }) => {
  const memoizedMachine = useMemo(() => telecomMachine, [])
  const [state, send] = useMachine(memoizedMachine)

  const memoizedValue = useMemo(
    () => ({
      select: (selector, ...args) => selector(state, ...args),
      state,
      send,
    }),
    [state, send]
  )

  return (
    <TelecomContext.Provider value={memoizedValue}>
      {children}
    </TelecomContext.Provider>
  )
}

export default TelecomContextController
