import { schema } from 'normalizr'

import { INSTITUTE_ENTITY } from '_v3/institute/constants'

import careTeamSchema from '_v3/care-team/schemas/careTeamSchema'

const instituteSchema = new schema.Entity(
  INSTITUTE_ENTITY,
  {
    careTeamIds: [careTeamSchema],
  },
  {
    idAttribute: 'slug',
    processStrategy: ({ slug, institute, care_team }) => ({
      id: slug,
      name: institute,
      careTeamIds: Array.isArray(care_team) ? care_team : [],
    }),
  }
)

export default instituteSchema
