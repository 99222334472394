export const createUserAddress = {
  method: 'post',
  path: '/personal/:username/address',
  transformBody: ({
    profile,
    street1,
    street2,
    zipcode,
    area,
    city,
    state,
  }) => ({
    id: profile,
    street_1: street1,
    street_2: street2,
    zipcode,
    area,
    city,
    state,
    country: 'US',
    primary: true,
    active: true,
  }),
}

export default createUserAddress
