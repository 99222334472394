import upperFirst from 'lodash/upperFirst'

export const getUserTitles = {
  method: 'get',
  path: '/personal/:username/titles',
  transformData: (data) =>
    data.results.map(({ id, title }) => ({
      id,
      title: upperFirst(title),
    })),
}

export default getUserTitles
