export const getUser = {
  method: 'get',
  path: '/auth/user',
  transformData: ({
    id,
    photo,
    username,
    email,
    first_name: firstName,
    last_name: lastName,
    institute: institutes,
    care_team: careTeams,
    profile_type: roles,
    terms_and_conditions: termsAndConditions,
  }) => {
    const instituteId =
      institutes && institutes[0]
        ? String(institutes[0]).length === 1
          ? institutes
          : institutes[0]
        : null

    const careTeam =
      institutes &&
      institutes[0] &&
      institutes[0].care_team &&
      institutes[0].care_team[0]
        ? institutes[0].care_team[0].slug
        : ''

    const _roles = roles ? roles.map(({ profile_type }) => profile_type) : []
    return {
      id,
      photo,
      username,
      email,
      firstName,
      lastName,
      fullName: `${lastName}, ${firstName}`,
      roles: roles.map(({ profile_type }) => profile_type),
      instituteId,
      careTeam,
      careTeams: careTeams ? careTeams.map(({ slug }) => slug) : [],
      careTeamsWithNames: careTeams,
      isFinancial: _roles.indexOf('financial') !== -1,
      isCeo: _roles.indexOf('ceo') !== -1,
      isCeoOnly:
        _roles.indexOf('ceo') !== -1 &&
        (_roles.length === 0 || _roles.length === 1),
      isAdmin: _roles.indexOf('administrator') !== -1,
      isManager: _roles.indexOf('manager') !== -1,
      isCoordinator: _roles.indexOf('coordinator') !== -1,
      isPhysician: _roles.indexOf('physician') !== -1,
      isNurse: _roles.indexOf('nurse') !== -1,
      isInstituteNurse: _roles.indexOf('institute_nurse') !== -1,
      isAdvisor: _roles.indexOf('advisor') !== -1,
      isCounselor: _roles.indexOf('counselor') !== -1,
      isPatient: _roles.indexOf('patient') !== -1,
      isSurveyAdmin: _roles.indexOf('survey') !== -1,
      isEducationAdmin: _roles.indexOf('education') !== -1,
      termsAndConditions,
    }
  },
  redispatchOn: ['updateUserProfile', 'createUserPhoto'],
}

export default getUser
