const redirectToHomepage = () => {
  window.location.href = '/'
}

const logout = async () => {
  localStorage.removeItem('token')
  localStorage.removeItem('video-token')
  localStorage.removeItem('SessionStore')
  localStorage.removeItem('user.isPatient')
  localStorage.removeItem('user.voiceToken')

  if (window.chatClient) {
    return window.chatClient.user
      .updateAttributes({ online: false, web: true })
      .then(redirectToHomepage, redirectToHomepage)
  }

  redirectToHomepage()
}

export default logout
