import React from 'react'
import styled from 'styled-components'

import { Dialog as BaseDialog, Classes } from '@blueprintjs/core'

const Wrapper = styled(BaseDialog)`
  background: white;
  min-width: ${({ minWidth = 600 }) =>
    `${typeof minWidth === 'string' ? minWidth : minWidth + 'px'}`};
  max-width: ${({ maxWidth = 'auto' }) =>
    maxWidth === 'auto' ? maxWidth : `${maxWidth}px`};
  min-height: ${({ minHeight = 'auto' }) =>
    `${typeof minHeight === 'string' ? minHeight : minHeight + 'px'}`};
  max-height: ${({ maxHeight = 'auto' }) =>
    maxHeight === 'auto' ? maxHeight : `${maxHeight}px`};
`

const Dialog = ({ actions, children, ...props }) => (
  <Wrapper {...props}>
    <div className={Classes.DIALOG_BODY}>{children}</div>
    {actions && (
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>{actions}</div>
      </div>
    )}
  </Wrapper>
)

export default Dialog
