const createNote = {
  method: 'post',
  path: '/engagement/:careTeamSlug/note',
  transformBody: ({ engagementId: engagement, note }) => ({
    timestamp: new Date(),
    engagement,
    note,
  }),
}

export default createNote
