import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import View from 'components/View'
import COLORS from 'constants/COLORS'
import SPACING from 'constants/SPACING'
import Text from 'components/Text'
import Grid from 'components/Grid'
import Toggler from 'components/Toggler'
import Icon from 'components/Icon'

const Card = ({
  title,
  title_icon,
  title_color,
  headerButton,
  alignment,
  isSmall,
  isTitleSmall,
  isToggleable,
  isInitiallyToggled,
  onClick,
  children,
  ...rest
}) => {
  const spacing = SPACING * (isSmall ? 0.75 : 1.1)
  const _children = Children.map(children, (child) => {
    if (!child) {
      return null
    }
    return child.type === Grid
      ? cloneElement(child, {
          alignment: child.props.alignment || alignment,
        })
      : child
  })
  return (
    <Toggler>
      {({ isToggled, toggle }) => (
        <View
          props={{ onClick }}
          backgroundColor="#fff"
          border={
            isToggleable
              ? null
              : `${isSmall || isTitleSmall ? 1 : 2}px solid ${COLORS.gray3}`
          }
          textAlign={alignment === 'center' ? alignment : null}
          padding={isToggleable ? null : spacing}
          // height="100%"
          {...rest}
        >
          {title && (
            <View
              props={{ onClick: isToggleable ? toggle : null }}
              display={headerButton ? 'flex' : null}
              justifyContent={headerButton ? 'space-between' : null}
              alignItems={headerButton ? 'center' : null}
              minHeight={isSmall ? 24 : 32}
              marginBottom={isToggleable ? 20 : spacing * 0.75}
              cursor={isToggleable ? 'pointer' : null}
            >
              <Text
                lineHeight={headerButton ? 1 : `${isSmall ? 24 : 32}px`}
                fontWeight={isToggleable ? 'bold' : 'normal'}
                size={isToggleable ? 4 : isTitleSmall ? 3 : isSmall ? 3 : 1}
                color={title_color}
              >
                {isToggleable && (
                  <Text size={5}>
                    <Icon
                      name={
                        (isInitiallyToggled ? !isToggled : isToggled)
                          ? 'minus-square-o'
                          : 'plus-square-o'
                      }
                    />
                  </Text>
                )}{' '}
                {title_icon && (
                  <View marginRight={10} display="inline-block">
                    <Icon name={title_icon} color="#0a7fba" />
                  </View>
                )}
                {title}
              </Text>
              {headerButton}
            </View>
          )}
          {isToggleable
            ? (isInitiallyToggled ? !isToggled : isToggled) && _children
            : _children}
        </View>
      )}
    </Toggler>
  )
}

Card.propTypes = {
  title: PropTypes.node,
  headerButton: PropTypes.node,
  alignment: PropTypes.oneOf(['center']),
  isSmall: PropTypes.bool,
  children: PropTypes.node,
}

export default Card
