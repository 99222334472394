import React from 'react'
import PropTypes from 'prop-types'

import Box from '_v3/core/views/Box'

const VerticalSpacer = ({ size }) => <Box height={size} flexShrink={0} />

VerticalSpacer.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'])
    .isRequired,
}

export default VerticalSpacer
