import * as R from 'ramda'
import React, { Fragment } from 'react'
import { navigate } from '@reach/router'
import { useLocation } from 'react-use'

import Col from '_v3/core/views/Col'
import Row from '_v3/core/views/Row'
import Container from '_v3/core/views/Container'

import NavBar from '_v3/core/views/NavBar'
import NavBarItem from '_v3/core/views/NavBarItem'
import Logo from '_v3/core/views/Logo'

import CallNavBarItem from '_v3/core/widgets/NavBarWidget/CallNavBarItem'
import UserProfileNavBarItem from '_v3/core/widgets/NavBarWidget/UserProfileNavBarItem'
import AdministrationNavBarItem from '_v3/core/widgets/NavBarWidget/AdministrationNavBarItem'
import ReportsNavBarItem from '_v3/core/widgets/NavBarWidget/ReportsNavBarItem'

import useDevice from 'hooks/useDevice'
import useUser from 'hooks/useUser'
import { CallQueueList } from './CallQueueList'
import { Position } from '@blueprintjs/core'

const NavBarWidget = () => {
  const { isPhone } = useDevice()
  const { user } = useUser()
  const location = useLocation()

  const isAdmin = R.prop('isAdmin', user)
  const isManager = R.prop('isManager', user)
  const isAdvisor = R.prop('isAdvisor', user)
  const isNurse = R.prop('isNurse', user)
  const isInstituteNurse = R.prop('isInstituteNurse', user)
  const isCoordinator = R.prop('isCoordinator', user)
  const isCounselor = R.prop('isCounselor', user)
  const isPhysician = R.prop('isPhysician', user)
  const isPatient = R.prop('isPatient', user)

  return (
    <NavBar>
      <Container size="lg">
        <Row justifyContent="space-between">
          <Row alignItems="center">
            {isPhone && (
              <Col paddingLeft="md" paddingRight="lg" alignItems="center">
                <Logo size="sm" />
              </Col>
            )}
            <Fragment>
              {isPatient ? (
                <Fragment>
                  <NavBarItem
                    label="Dashboard"
                    leftIcon="dashboard"
                    isActive={location.pathname.match('/dashboard')}
                    onClick={() => navigate('/dashboard')}
                  />
                  <NavBarItem
                    label="Tracking"
                    leftIcon="bar-chart"
                    isActive={location.pathname.match('/tracking')}
                    onClick={() => navigate('/tracking')}
                  />
                  <NavBarItem
                    label="Calendar"
                    leftIcon="calendar"
                    isActive={location.pathname.match('/calendar')}
                    onClick={() => navigate('/calendar')}
                  />
                  <NavBarItem
                    label="Messaging"
                    leftIcon="comment"
                    isActive={location.pathname.match('/messaging')}
                    onClick={() => navigate('/messaging')}
                  />
                  <NavBarItem
                    label="Medical"
                    leftIcon="medkit"
                    isActive={location.pathname.match('/medical')}
                    onClick={() => navigate('/medical')}
                  />
                </Fragment>
              ) : (
                (isAdmin ||
                  isManager ||
                  isPhysician ||
                  isNurse ||
                  isInstituteNurse ||
                  isCoordinator ||
                  isAdvisor ||
                  isCounselor) && (
                  <Fragment>
                    <NavBarItem
                      label="Dashboard"
                      leftIcon="dashboard"
                      isActive={location.pathname.match('/dashboard')}
                      onClick={() => navigate('/dashboard')}
                    />
                    <NavBarItem
                      label="Care Team"
                      leftIcon="group"
                      isActive={location.pathname.match('/care-team')}
                      onClick={() => navigate('/care-team')}
                    />
                    {isPhysician && (
                      <NavBarItem
                        label="Approvals"
                        leftIcon="check"
                        isActive={location.pathname.match('/approvals')}
                        onClick={() => navigate('/approvals')}
                      />
                    )}
                    <ReportsNavBarItem location={location} />
                    {(isManager || isAdvisor || isCoordinator || isCounselor) &&
                      (isManager ? (
                        <NavBarItem
                          label="Team Management"
                          leftIcon="calendar"
                          dropdownItems={[
                            {
                              label: 'Team Mangement',
                              leftIcon: 'group',
                              isActive: location.pathname.match(
                                '/team-management'
                              ),
                              onClick: () => navigate('/team-management'),
                            },
                            {
                              label: 'Work Commitment',
                              leftIcon: 'calendar-check-o',
                              isActive: location.pathname.match(
                                '/work-commitment'
                              ),
                              onClick: () => navigate('/work-commitment'),
                            },
                          ]}
                          dropdownPlacement={Position.BOTTOM}
                        />
                      ) : (
                        <NavBarItem
                          label="Work Commitment"
                          leftIcon="calendar"
                          isActive={location.pathname.match('/work-commitment')}
                          onClick={() => navigate('/work-commitment')}
                        />
                      ))}
                    <AdministrationNavBarItem location={location} />
                  </Fragment>
                )
              )}
            </Fragment>
          </Row>
          <Row alignItems="center">
            <CallQueueList showsActivity={isCoordinator || isAdvisor} />
            <CallNavBarItem />
            <UserProfileNavBarItem location={location} user={user} />
          </Row>
        </Row>
      </Container>
    </NavBar>
  )
}

export default NavBarWidget
