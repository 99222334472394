import React from 'react'
import PropTypes from 'prop-types'
import { Block } from 'jsxstyle'

const View = (props) => <Block {...props} />

View.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  props: PropTypes.object,
  children: PropTypes.node,
}

export default View
