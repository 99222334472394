import * as R from 'ramda'
import { camelizeKeys } from 'humps'

const getPrograms = {
  method: 'get',
  path: '/institute/:instituteId/program',
  transformData: (data) => {
    return camelizeKeys(data.results).map((item) =>
      R.assoc('programId', item.id, item)
    )
  },
}

export default getPrograms
