import React, { lazy } from 'react'
import { Router } from '@reach/router'

import { retry } from 'systems/Lazy'

const LoginScreen = lazy(() =>
  retry(() => {
    return import('screens/LoginScreen')
  })
)
const RegisterScreen = lazy(() =>
  retry(() => {
    return import('screens/RegisterScreen')
  })
)
const ResetPasswordScreen = lazy(() =>
  retry(() => {
    return import('screens/ResetPasswordScreen')
  })
)
const ConfirmResetPasswordScreen = lazy(() =>
  retry(() => {
    return import('screens/ConfirmResetPasswordScreen')
  })
)
const NotFoundScreen = lazy(() =>
  retry(() => {
    return import('screens/NotFoundScreen')
  })
)
const TokenTrace = lazy(() =>
  retry(() => {
    return import('screens/TokenTrace')
  })
)

const PublicRoutes = () => (
  <Router>
    <LoginScreen path="/" />
    <RegisterScreen path="/register" />
    <ResetPasswordScreen path="/reset-password" />
    <ResetPasswordScreen path="/reset-password" />
    <TokenTrace path="/token-trace/:token" />
    <ConfirmResetPasswordScreen path="/reset-password/:uid/:token" />
    <NotFoundScreen default />
  </Router>
)

export default PublicRoutes
