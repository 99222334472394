class Emitter {
  listeners = []

  emit = (...args) => this.listeners.forEach((listener) => listener(...args))

  subscribe = (listener) => {
    this.listeners.push(listener)
    return listener
  }

  unsubscribe = (listener) =>
    this.listeners.splice(this.listeners.indexOf(listener), 1)
}

export default Emitter
