import { useContext } from 'react'
import get from 'lodash/get'

import EntityStore from '_v3/core/stores/EntityStore'

export const useEntityService = () => {
  const entityStore = useContext(EntityStore.context)

  const getEntities = (entitySymbol, { isFallbackEnabled = false }) => {
    const state = entityStore.getState()
    let allIds = get(state, `${entitySymbol}.allIds`, [])
    if (allIds.length === 0 && isFallbackEnabled)
      allIds = Object.keys(get(state, `${entitySymbol}.byId`, {}))
    return allIds.map((id) => getEntity(entitySymbol, id))
  }

  const getEntity = (entitySymbol, entityId) =>
    get(entityStore.getState(), `${entitySymbol}.byId[${entityId}]`, null)

  const mergeEntities = (entities) =>
    entityStore.setState((state) =>
      Object.entries(entities).reduce(
        (state, [entitySymbol, entities]) => ({
          ...state,
          [entitySymbol]: state[entitySymbol]
            ? {
                byId: {
                  ...state[entitySymbol].byId,
                  ...entities,
                },
                allIds: state[entitySymbol].allIds,
              }
            : {
                byId: entities,
                allIds: [],
              },
        }),
        state
      )
    )

  const setEntityIds = (entitySymbol, entityIds) =>
    entityStore.setState((state) => ({
      [entitySymbol]: {
        byId: state[entitySymbol].byId,
        allIds: entityIds,
      },
    }))

  return {
    getEntities,
    getEntity,
    mergeEntities,
    setEntityIds,
  }
}
