import React, { useMemo } from 'react'
import { useMachine } from '@xstate/react'

import UserContext from './UserContext'
import userMachine from './userMachine'

const UserContextController = ({ children }) => {
  const memoizedMachine = useMemo(() => userMachine, [])
  const service = useMachine(memoizedMachine)

  const memoizedValue = useMemo(
    () => ({
      state: service[0],
      send: service[1],
    }),
    [service]
  )

  return (
    <UserContext.Provider value={memoizedValue}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextController
