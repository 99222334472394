import dayjs from 'dayjs'

const createInstituteAddress = {
  method: 'post',
  path: '/institute/:instituteId/address',
  transformBody: ({
    instituteId: institute,
    street1: street_1,
    street2: street_2,
    zipcode,
    area,
    city,
    state,
  }) => ({
    institute,
    street_1,
    street_2,
    zipcode,
    area,
    city,
    state,
    country: 'US',
    timezone: dayjs().utcOffset(),
    primary: true,
    active: true,
  }),
}

export default createInstituteAddress
