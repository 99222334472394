const getUserProfile = {
  method: 'get',
  path: '/personal/:username/profile',
  transformData: ({
    username,
    title: titleId,
    first_name: firstName,
    initials,
    last_name: lastName,
    date_of_birth: dateOfBirth,
    gender: genderId,
    ethnicity: ethnicityId,
    email,
    phone_number: phone,
    mobile_number: mobile,
    ...rest
  }) => ({
    username,
    titleId,
    firstName,
    initials,
    lastName,
    fullName: `${lastName}, ${firstName}`,
    dateOfBirth,
    genderId,
    ethnicityId,
    email,
    phone,
    mobile,
    isPatient: Array.isArray(rest.profile_type)
      ? Boolean(
          rest.profile_type.find(
            ({ profile_type }) => profile_type === 'patient'
          )
        )
      : false,
    type: rest.profile_type,
    ...rest,
  }),
  redispatchOn: ['disablePatient'],
}

export default getUserProfile
