import color from 'color'

import MAX_APP_WIDTH from 'constants/MAX_APP_WIDTH'

const black = '#000'
const white = '#fff'

const darkGray1 = '#404040'
const darkGray2 = color(darkGray1).lighten(0.5 * 1)
const darkGray3 = color(darkGray1).lighten(0.5 * 2)

const gray1 = '#ccc'
const gray2 = color(gray1).lighten(0.05 * 1)
const gray3 = color(gray1).lighten(0.05 * 2)

const lightGray1 = '#ddd'
const lightGray2 = color(lightGray1).lighten(0.05 * 1)
const lightGray3 = color(lightGray1).lighten(0.05 * 2)

const green1 = '#bfd45f'
const green2 = '#2D8659'

const blue1 = '#187fb5'

const theme = {
  colors: {
    black,
    white,

    darkGray1,
    darkGray2,
    darkGray3,

    gray1,
    gray2,
    gray3,

    lightGray1,
    lightGray2,
    lightGray3,

    green1,
    green2,

    blue1,
  },

  gradients: {
    lightGray: [lightGray3, lightGray1],
  },

  boxShadows: {
    light: '0 8px 8px rgba(10, 10, 10, .1)',
  },

  fontFamilies: {
    'sans-serif': "'Helvetica Neue', Arial, sans-serif",
  },

  containerSizes: {
    sm: 480,
    lg: MAX_APP_WIDTH,
  },

  logoSizes: {
    sm: 36,
  },

  avatarSizes: {
    xs: 26,
    sm: 40,
    md: 52,
  },

  borderRadiusSizes: {
    xs: 5,
    sm: 7.5,
    md: 10,
    lg: 12.5,
    xl: 15,
  },

  headingSizes: {
    xs: 24,
    sm: 28,
    md: 32,
    lg: 36,
    xl: 40,
  },

  textSizes: {
    xxs: 11,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
  },

  iconSizes: {
    xxs: 11,
    xs: 16,
    sm: 18,
    md: 20,
    lg: 22,
    xl: 24,
  },

  lineHeightSizes: {
    xs: 18,
    sm: 22,
    md: 24,
    lg: 28,
    xl: 30,
  },

  spacingSizes: {
    xs: 5,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 30,
    xxl: 40,
    xxxl: 50,
  },
}

export default theme
