import React from 'react'
import PropTypes from 'prop-types'

import logo from '_v3/core/assets/logo.png'
import Box from '_v3/core/views/Box'
import { useTheme } from 'hooks/useTheme'

const Logo = ({ size }) => {
  const { logoSizes } = useTheme()
  return (
    <Box
      as="img"
      props={{ src: logo, alt: '4D Healthware' }}
      maxHeight={logoSizes[size]}
    />
  )
}

Logo.propTypes = { size: PropTypes.oneOf(['sm']).isRequired }

export default Logo
