import React from 'react'
import PropTypes from 'prop-types'

import Box from '_v3/core/views/Box'

const Text = ({
  intent,
  letterSpacing,
  transform,
  size,
  align,
  isBold,
  children,
}) => (
  <Box
    as="span"
    color={
      { primary: 'blue1', secondary: 'darkGray1', tertiary: 'darkGray2' }[
        intent
      ]
    }
    fontFamily="sansSerif"
    fontWeight={isBold ? 700 : 400}
    fontSize={size}
    letterSpacing={letterSpacing}
    lineHeight={size}
    textTransform={transform}
    textAlign={align}
  >
    {children}
  </Box>
)

Text.propTypes = {
  intent: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  letterSpacing: PropTypes.number,
  transform: PropTypes.oneOf(['uppercase']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  align: PropTypes.oneOf(['center', 'right']),
  isBold: PropTypes.bool,
  children: PropTypes.node,
}

Text.defaultProps = {
  intent: 'secondary',
  size: 'sm',
}

export default Text
