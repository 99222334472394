const hasInitializationError = (state) =>
  state.matches('initializing.loadingToken.failure') ||
  state.matches('initializing.activatingDevice.failure')

const telecomSelectors = {
  isInitializing: (state) => state.matches('initializing'),
  isIdle: (state) => state.matches('idle'),
  isIncoming: (state) => state.matches('incoming'),
  isOutgoing: (state) => state.matches('outgoing'),
  isAwaitingResponse: (state) => state.matches('incoming.idle'),
  isConnected: (state) =>
    state.matches('incoming.connected') || state.matches('outgoing.connected'),
  hasInitializationError,
  hasPendingWork: (state) =>
    (state.matches('initializing') ||
      state.matches('incoming.accepted') ||
      state.matches('incoming.disconnecting') ||
      state.matches('outgoing.connecting') ||
      state.matches('outgoing.disconnecting')) &&
    !hasInitializationError(state),
  canTransfer: (state) => {
    return (
      state.matches('outgoing.connected') || state.matches('incoming.connected')
    )
  },
  getCallerName: (state) => {
    let unknown = state.matches('incoming') ? 'Unknown Caller' : null
    return state.context.caller ? state.context.caller.name || unknown : unknown
  },
  getErrorMessage: (state) =>
    state.context.error ? state.context.error.message : null,
}

export default telecomSelectors
