import * as R from 'ramda'
import React from 'react'
import { navigate } from '@reach/router'
import { useLocation } from 'react-use'

import NavBarItem from '_v3/core/views/NavBarItem'
import Avatar from '_v3/core/views/Avatar'
import useUser from 'hooks/useUser'
import { queueMachine } from './CallQueueList'
import { useGlobalMachine } from 'utils/createGlobalMachine'
import { Position } from '@blueprintjs/core'

const getNeedWorker = () => {
  try {
    return JSON.parse(localStorage.needWorker)
  } catch (err) {
    return null
  }
}

const UserProfileNavBarItem = () => {
  const location = useLocation()
  const { user } = useUser()
  const [, send] = useGlobalMachine(queueMachine)

  const needWorker = getNeedWorker()
  const fullName = R.prop('fullName', user)
  const isPatient = R.prop('isPatient', user)

  function handleLogout() {
    if (!needWorker) {
      navigate('/logout')
      return
    }

    send('WORKER_OFFLINE', {
      onAfterOffline: () => {
        navigate('/logout')
      },
    })
  }

  const dropdownItems = [
    {
      label: 'Profile',
      leftIcon: 'user',
      isActive: !!location.pathname.match('/profile'),
      onClick: () => navigate('/profile'),
    },
    {
      label: 'Settings',
      leftIcon: 'cog',
      isActive: !!location.pathname.match('/settings'),
      onClick: () => navigate('/settings'),
    },
    isPatient && {
      label: 'My Devices',
      leftIcon: 'tablet',
      isActive: !!location.pathname.match('/my-devices'),
      onClick: () => navigate('/my-devices'),
    },
    {
      label: 'Logout',
      leftIcon: 'sign-out',
      isActive: !!location.pathname.match('/logout'),
      onClick: handleLogout,
    },
  ]

  return (
    <NavBarItem
      label={fullName}
      leftAddon={<Avatar size="xs" />}
      dropdownItems={dropdownItems.filter(Boolean)}
      dropdownPlacement={Position.BOTTOM}
    />
  )
}

export default UserProfileNavBarItem
