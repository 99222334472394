import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'font-awesome/css/font-awesome.css'
import 'index.css'

import * as R from 'ramda'
import React from 'react'
import { render } from 'react-dom'
import dayjs from 'dayjs'

import App from 'components/App'
import dayjsPlugin from 'utils/dayjs'

dayjs.extend(dayjsPlugin)

if (process.env.NODE_ENV === 'production') {
  window.Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    ignoreErrors: ['Non-Error exception captured'],
    beforeSend(event, hint) {
      const value = R.path(['exception', 'values', '0', 'value'], event)
      const message = R.path('originalException', 'message', hint)
      const isNonErrorException =
        (value && value.startsWith('Non-Error exception captured')) ||
        (message && message.startsWith('Non-Error exception captured'))

      if (isNonErrorException) return null
      return event
    },
  })
}

render(<App />, document.getElementById('root'))
