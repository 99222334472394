import 'react-datepicker/dist/react-datepicker.css'

import React from 'react'
import dayjs from 'dayjs'
import RDatePicker from 'react-datepicker'

const DatePicker = ({ value = new Date(), ...props }) => (
  <RDatePicker
    {...props}
    selected={dayjs(value).toDate()}
    popperModifiers={{
      preventOverflow: {
        boundariesElement: 'viewport',
        enabled: true,
      },
    }}
  />
)

export default DatePicker
