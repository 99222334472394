const updateEngagement = {
  method: 'patch',
  path: '/engagement/:careTeamSlug/engagement/:engagementId',
  transformBody: ({
    eventId: event,
    ownerId: owner,
    started,
    completed,
    calls = [],
    notes = [],
    billable,
    avoidanceReason,
    visitAvoided,
  }) => ({
    event,
    owner,
    started,
    completed,
    calls,
    notes,
    billable,
    avoidance_reason: avoidanceReason,
    visit_avoided: visitAvoided,
  }),
}

export default updateEngagement
