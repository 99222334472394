import React from 'react'

import { Switch as JSwitch } from '@blueprintjs/core'

function Switch({ label, value, disabled, onChange, ...props }) {
  return (
    <JSwitch
      {...props}
      label={label}
      checked={value}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked)}
    />
  )
}

export default Switch
