import React, { Suspense, lazy } from 'react'
import { Router, Redirect, navigate } from '@reach/router'
import { Position, Toaster as BaseToaster } from '@blueprintjs/core'

import NavBarWidget from '_v3/core/widgets/NavBarWidget'
import Spinner from 'components/Spinner'
import { retry } from 'systems/Lazy'
import { useChannel } from 'systems/RealTime'
import { useSessionService } from 'hooks/useSessionService'
import { queryCache } from 'react-query'

let Toaster = BaseToaster.create({ position: Position.TOP_RIGHT })

const PatientTrackingScreen = lazy(() =>
  retry(() => {
    return import('screens/PatientTrackingScreen')
  })
)
const PatientCalendarScreen = lazy(() =>
  retry(() => {
    return import('screens/PatientCalendarScreen')
  })
)
const MessagingScreen = lazy(() =>
  retry(() => {
    return import('screens/MessagingScreen')
  })
)
const PatientMedicalScreen = lazy(() =>
  retry(() => {
    return import('screens/PatientMedicalScreen')
  })
)
const DashboardScreen = lazy(() =>
  retry(() => {
    return import('screens/DashboardScreen')
  })
)
const EventScreen = lazy(() =>
  retry(() => {
    return import('screens/EventScreen')
  })
)
const CareTeamScreen = lazy(() =>
  retry(() => {
    return import('screens/CareTeamScreen')
  })
)
const InstituteProfileScreen = lazy(() =>
  retry(() => {
    return import('screens/InstituteProfileScreen')
  })
)
const SurveyAdminScreen = lazy(() =>
  retry(() => {
    return import('screens/SurveyAdminScreen')
  })
)
const EducationAdminScreen = lazy(() =>
  retry(() => {
    return import('screens/EducationAdminScreen')
  })
)
const UserProfileScreen = lazy(() =>
  retry(() => {
    return import('screens/UserProfileScreen')
  })
)
const UserSettingsScreen = lazy(() =>
  retry(() => {
    return import('screens/UserSettingsScreen')
  })
)
const UserDevicesScreen = lazy(() =>
  retry(() => {
    return import('screens/UserDevicesScreen')
  })
)
const ApprovalsScreen = lazy(() =>
  retry(() => {
    return import('screens/ApprovalsScreen')
  })
)
const WorkCommitmentScreen = lazy(() =>
  retry(() => {
    return import('screens/WorkCommitmentScreen')
  })
)
const TeamManagementScreen = lazy(() =>
  retry(() => {
    return import('screens/TeamManagementScreen')
  })
)
const LogoutScreen = lazy(() =>
  retry(() => {
    return import('screens/LogoutScreen')
  })
)
const NotFoundScreen = lazy(() =>
  retry(() => {
    return import('screens/NotFoundScreen')
  })
)
const AccumulatedTimeReport = lazy(() =>
  retry(() => {
    return import('reports/AccumulatedTimeReport')
  })
)
const BillablePatientsReport = lazy(() =>
  retry(() => {
    return import('reports/BillablePatientsReport')
  })
)
const DailyGoalsReport = lazy(() =>
  retry(() => {
    return import('reports/DailyGoalsReport')
  })
)
const TeamPerformanceReport = lazy(() =>
  retry(() => {
    return import('reports/TeamPerformanceReport')
  })
)
const ComparePatients = lazy(() =>
  retry(() => {
    return import('reports/ComparePatients')
  })
)
const DailyAdvisorPerformanceReport = lazy(() =>
  retry(() => {
    return import('reports/DailyAdvisorPerformanceReport')
  })
)
const DailyBillableScreen = lazy(() =>
  retry(() => {
    return import('screens/DailyBillableScreen')
  })
)
const AdvisorEfficiencyScreen = lazy(() =>
  retry(() => {
    return import('screens/AdvisorEfficiencyScreen')
  })
)
const AssignEventScreen = lazy(() =>
  retry(() => {
    return import('screens/AssignEventScreen')
  })
)
const PatientsScreen = lazy(() =>
  retry(() => {
    return import('screens/PatientsScreen')
  })
)

const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <Spinner size="lg" />
  </div>
)

function Notifications(props) {
  useChannel({
    path: `/care_team/${props.careTeamId}`,
    handleMessage: (data) => {
      if (data.type === 'alert.new') {
        Toaster.show({
          intent: data.intent,
          icon: data.icon,
          message: data.message,
          timeout: data.timeout ? data.timeout * 1000 : undefined,
          action: data.event
            ? {
                text: 'View Event',
                onClick: () => navigate(`/events/${data.event}`),
              }
            : undefined,
        })
        queryCache.invalidateQueries(['getEvents'])
      }
    },
  })

  return null
}

const PrivateRoutes = () => {
  const { careTeamId } = useSessionService()
  return (
    <>
      <NavBarWidget />
      <Notifications careTeamId={careTeamId} />
      <Suspense fallback={<Loading />}>
        <Router>
          <Redirect from="/" to="/dashboard" noThrow />
          <DashboardScreen path="/dashboard" />
          <EventScreen path="/events/:eventId/*" />
          <CareTeamScreen path="/care-team/*" />
          <PatientTrackingScreen path="/tracking" />
          <PatientCalendarScreen path="/calendar" />
          <MessagingScreen path="/messaging/*" />
          <PatientMedicalScreen path="/medical" />
          <PatientsScreen path="/patients/*" />
          <InstituteProfileScreen path="/institute/*" />
          <SurveyAdminScreen path="/survey-admin" />
          <EducationAdminScreen path="/education-admin" />
          <UserProfileScreen path="/profile/*" />
          <UserSettingsScreen path="/settings" />
          <UserDevicesScreen path="/my-devices" />
          <AccumulatedTimeReport path="/reports/accumulated-time/*" />
          <BillablePatientsReport path="/reports/billable-patients/*" />
          <DailyGoalsReport path="/reports/daily-goals" />
          <TeamPerformanceReport path="/reports/clinical-team-performance" />
          <ComparePatients path="/reports/compare-patients" />
          <DailyAdvisorPerformanceReport path="/reports/advisor-performance" />
          <ApprovalsScreen path="/approvals" />
          <WorkCommitmentScreen path="/work-commitment" />
          <TeamManagementScreen path="/team-management" />
          <DailyBillableScreen path="/reports/daily-billable" />
          <AdvisorEfficiencyScreen path="/reports/advisor-efficiency" />
          <AssignEventScreen path="/calls/assign-event/*" />
          <LogoutScreen path="/logout" />
          <NotFoundScreen default />
        </Router>
      </Suspense>
    </>
  )
}

export default PrivateRoutes
