import React from 'react'
import PropTypes from 'prop-types'

import InlineCol from '_v3/core/views/InlineCol'
import { useTheme } from 'hooks/useTheme'

const Icon = ({ intent, name, size, hasFixedWidth }) => {
  const { iconSizes } = useTheme()
  return (
    <InlineCol
      as="span"
      className={`fa fa-${name}`}
      color={
        {
          primary: 'blue1',
          secondary: 'darkGray1',
          tertiary: 'darkGray2',
          success: 'green2',
        }[intent]
      }
      fontSize={`${iconSizes[size]}px !important`}
      lineHeight={`${iconSizes[size]}px !important`}
      textAlign="center"
      width={hasFixedWidth ? iconSizes[size] : null}
    />
  )
}

Icon.propTypes = {
  intent: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'success']),
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
  hasFixedWidth: PropTypes.bool,
}

Icon.defaultProps = {
  intent: 'secondary',
  size: 'sm',
  hasFixedWidth: false,
}

export default Icon
