import React, { Children, isValidElement } from 'react'

import 'styled-components/macro'
import { theme } from 'themeUtils'

const HSpacing = ({ size, children }) =>
  Children.toArray(children).map((child, index, children) => (
    <div
      key={index}
      css={`
        margin-right: ${theme(
          'spacing.' + (isValidElement(children[index + 1]) ? size : 0)
        )};
      `}
    >
      {child}
    </div>
  ))

export default HSpacing
