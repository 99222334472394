const createEngagement = {
  method: 'post',
  path: '/engagement/:careTeamSlug/engagement',
  transformBody: ({
    eventId: event,
    ownerId: owner,
    started,
    completed,
    calls = [],
    notes = [],
    billable,
  }) => ({
    event,
    owner,
    started,
    completed,
    calls,
    notes,
    billable,
  }),
}

export default createEngagement
