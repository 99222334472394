import React from 'react'

import LegacyCard from 'components/Card'

const Card = ({ title, icon, children }) => (
  <LegacyCard
    title={title}
    title_icon={icon}
    title_color={icon && '#0a7fba'}
    isTitleSmall={true}
  >
    {children}
  </LegacyCard>
)

export default Card
