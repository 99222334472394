import { createMachine } from 'xstate'

export let channelMachine = createMachine(
  {
    id: 'channel',
    initial: 'idle',
    states: {
      idle: {
        invoke: {
          src: 'connect',
        },
        on: {
          MESSAGE: {
            actions: 'handleMessage',
          },
          DISCONNECTED: 'reconnecting',
        },
      },
      reconnecting: {
        after: {
          3000: 'idle',
        },
      },
    },
  },
  {
    services: {
      connect: (ctx) => (callback, onEvent) => {
        let socket = new WebSocket(
          process.env.REACT_APP_WSS_URL + ctx.path.slice(1)
        )

        socket.onopen = () => {
          callback('CONNECTED')
        }

        socket.onmessage = (e) => {
          callback({ type: 'MESSAGE', data: JSON.parse(e.data) })
        }

        socket.onclose = () => {
          callback('DISCONNECTED')
        }

        onEvent((e) => {
          if (e.type === 'SEND') {
            socket.send(JSON.stringify(e.data))
          }
        })

        return () => {
          socket.close()
        }
      },
    },
  }
)
