import upperFirst from 'lodash/upperFirst'

const getCareTeamSeverities = {
  method: 'get',
  path: '/engagement/:slug/severity',
  transformData: (data) =>
    data.results.map(({ id, severity: name }) => ({
      id,
      name: upperFirst(name),
    })),
}

export default getCareTeamSeverities
