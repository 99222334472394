import * as R from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import get from 'lodash/get'

import View from 'components/View'
import Text from 'components/Text'

const valueEq = R.propEq('value')
const getDefaultValue = (value, options) => {
  if (Array.isArray(value)) return value.map((id) => options.find(valueEq(id)))
  return options.find(valueEq(value))
}

const Select = ({
  label,
  value,
  placeholder,
  options,
  optionLabelPath,
  optionValuePath,
  noResultsText,
  isAutoFocus,
  isMultiple,
  isDisabled,
  onChange,
  clearable = false,
  ...rest
}) => {
  const _options =
    optionLabelPath && optionValuePath
      ? options.map((option) => ({
          label: get(option, optionLabelPath),
          value: get(option, optionValuePath),
        }))
      : options

  const __options = _options && _options.length ? _options : []
  const defaultValue = getDefaultValue(value, __options)

  const select = (
    <View {...rest}>
      <ReactSelect
        options={__options}
        value={defaultValue}
        placeholder={placeholder}
        delimiter=","
        autofocus={isAutoFocus}
        isMulti={isMultiple}
        isClearable={clearable}
        isDisabled={isDisabled}
        noOptionsMessage={() => noResultsText}
        onChange={(value) => {
          if (Array.isArray(value)) {
            onChange(isMultiple ? value.map(R.prop('value')) : '')
            return
          }
          onChange(value ? value.value : '')
        }}
      />
    </View>
  )
  return (
    <div>
      {label ? (
        <label>
          <Text marginBottom={5}>{label}</Text>
          {select}
        </label>
      ) : (
        select
      )}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  options: PropTypes.array.isRequired,
  optionLabelPath: PropTypes.string,
  optionValuePath: PropTypes.string,
  isAutoFocus: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

Select.defaultProps = {
  options: [],
  onChange: () => {},
}

export default Select

export const toOptions = (array, optionLabelPath, optionValuePath) =>
  array.map((item) => ({
    label: item[optionLabelPath],
    value: item[optionValuePath],
  }))
