import { Component } from 'react'
import PropTypes from 'prop-types'
import Emitter from 'utils/Emitter'

const emitter = new Emitter()

class LocalStorage extends Component {
  static propTypes = {
    subscribeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    children: PropTypes.func.isRequired,
  }

  static defaultProps = {
    children: () => {},
  }

  componentDidMount() {
    this.subscribe(this.props.subscribeTo)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.unsubscribe()
    this.subscribe(nextProps.subscribeTo)
  }

  componentWillUnmount() {
    this.willUnmount = true
    this.unsubscribe()
  }

  subscribe = (subscribeTo) => {
    const subscribedKeys = subscribeTo ? [].concat(subscribeTo) : []
    this.subscriptions = subscribedKeys.map((subscribedKey) =>
      emitter.subscribe(
        (key) =>
          !this.willUnmount && key === subscribedKey && this.forceUpdate()
      )
    )
  }

  unsubscribe = () => this.subscriptions.forEach(emitter.unsubscribe)

  getItem = (key) => localStorage.getItem(key)

  setItem = (key, value) => {
    localStorage.setItem(key, value)
    emitter.emit(key)
  }

  removeItem = (key) => {
    localStorage.removeItem(key)
    emitter.emit(key)
  }

  render() {
    return (
      this.props.children({
        getItem: this.getItem,
        setItem: this.setItem,
        removeItem: this.removeItem,
      }) || null
    )
  }
}

export default LocalStorage
