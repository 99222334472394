import React from 'react'
import PropTypes from 'prop-types'
import COLORS from 'constants/COLORS'
import View from 'components/View'

const colorsByIntent = {
  heading: COLORS.blue4,
  secondary: COLORS.darkGray4,
  primary: COLORS.blue1,
  success: COLORS.green1,
  danger: COLORS.red1,
}

const Text = ({ intent, size, ...rest }) => (
  <View
    component="span"
    color={intent ? colorsByIntent[intent] : null}
    fontFamily="sans-serif"
    fontSize={
      size === 5.5 ? 12 : size === 6 ? 11 : size === 5 ? 13 : 30 - 4 * size
    }
    lineHeight={1.5}
    display="inline-block"
    {...rest}
  />
)

Text.propTypes = {
  intent: PropTypes.oneOf([
    'heading',
    'secondary',
    'primary',
    'success',
    'danger',
  ]),
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 5.5, 6]).isRequired,
}

Text.defaultProps = {
  size: 4,
}

export default Text
