import * as R from 'ramda'
import React, { Fragment, Children, createElement, isValidElement } from 'react'
import { Block } from 'jsxstyle'

import VerticalSpacer from '_v3/core/views/VerticalSpacer'
import HorizontalSpacer from '_v3/core/views/HorizontalSpacer'
import { useTheme } from 'hooks/useTheme'

const Box = ({
  as,
  className,
  props,
  flexDirection,
  inBetweenSpacing,
  children,
  ...rest
}) => {
  const {
    colors,
    gradients,
    boxShadows,
    fontFamilies,
    borderRadiusSizes,
    textSizes,
    lineHeightSizes,
    spacingSizes,
  } = useTheme()

  const flattenedChildren = Children.toArray(children)

  return (
    <Block
      component={as}
      className={className}
      props={props}
      {...R.toPairs(rest)
        .map(([propName, propValue]) => {
          if (propName === 'boxShadow' && boxShadows[propValue]) {
            return [propName, boxShadows[propValue]]
          }

          if (
            propName.match(/[Bb]order((Top|Bottom)(Left|Right))?Radius$/) &&
            borderRadiusSizes[propValue]
          )
            return [propName, borderRadiusSizes[propValue]]

          if (
            propName.match(/([Cc]olor|[Bb](orderColor|ackgroundColor))$/) &&
            propValue !== 'transparent'
          )
            return [propName, colors[propValue]]

          if (propName === 'fontFamily')
            return [propName, fontFamilies[propValue]]

          if (propName === 'fontSize' && textSizes[propValue])
            return [propName, textSizes[propValue]]

          if (propName === 'lineHeight' && lineHeightSizes[propValue])
            return [propName, `${lineHeightSizes[propValue]}px`]

          if (propName === 'width' && spacingSizes[propValue])
            return [propName, spacingSizes[propValue]]

          if (propName === 'height' && spacingSizes[propValue])
            return [propName, spacingSizes[propValue]]

          if (
            propName.match(/[Pp]adding(Top|Bottom|Left|Right)?$/) &&
            spacingSizes[propValue]
          )
            return [propName, spacingSizes[propValue]]

          return [propName, propValue]
        })
        .reduce((result, [propName, propValue], style) => {
          if (propName.match(/^active[A-Z]/))
            return {
              ...result,
              [propName]: `${propValue} !important`,
            }

          if (
            propName.match(/border(Top|Bottom|Left|Right)?Width$/) &&
            !style[propName.replace('Width', 'Style')]
          ) {
            return {
              ...result,
              [propName.replace('Width', 'Style')]: 'solid',
              [propName]: propValue,
            }
          }

          if (propName === 'backgroundGradient')
            return {
              ...result,
              backgroundImage: `linear-gradient(${gradients[propValue].join(
                ', '
              )})`,
            }

          if (propName === 'verticalPadding')
            return {
              ...result,
              paddingTop: propValue,
              paddingBottom: propValue,
            }

          if (propName === 'horizontalPadding')
            return {
              ...result,
              paddingLeft: propValue,
              paddingRight: propValue,
            }

          return {
            ...result,
            [propName]: propValue,
          }
        }, {})}
    >
      {inBetweenSpacing
        ? Children.map(flattenedChildren, (child, childIndex) => (
            <Fragment>
              {child}
              {isValidElement(flattenedChildren[childIndex + 1]) &&
                createElement(
                  flexDirection === 'row' ? HorizontalSpacer : VerticalSpacer,
                  { size: inBetweenSpacing }
                )}
            </Fragment>
          ))
        : children}
    </Block>
  )
}

export default Box
