import React, { Fragment, Children } from 'react'
import theme from './theme'

const Spacer = ({ size }) => (
  <span
    aria-hidden
    style={{
      width: theme.spacing[size],
      height: theme.spacing[size],
      display: 'block',
    }}
  />
)

const InBetweenSpacing = ({ size, children }) =>
  Children.toArray(children).map((child, index, children) => (
    <Fragment key={index}>
      {child.type === Fragment ? (
        <InBetweenSpacing size={size}>{child.props.children}</InBetweenSpacing>
      ) : (
        child
      )}
      {children[index + 1] && <Spacer size={size} />}
    </Fragment>
  ))

export default InBetweenSpacing
