const updateUserProfile = {
  method: 'patch',
  path: '/personal/:username/profile',
  transformBody: ({
    id,
    ssn,
    initials,
    email,
    titleId: title,
    firstName: first_name,
    lastName: last_name,
    dateOfBirth: date_of_birth,
    genderId: gender,
    ethnicityId: ethnicity,
    phone: phone_number,
    mobile: mobile_number,
    termsAndConditions: terms_and_conditions,
  }) => ({
    id,
    title,
    first_name,
    initials,
    last_name,
    date_of_birth,
    gender,
    ethnicity,
    email,
    phone_number,
    mobile_number,
    terms_and_conditions,
    ssn: ssn || '',
  }),
}

export default updateUserProfile
