import React, { useEffect } from 'react'
import styled from 'styled-components'

import { connect } from 'twilio-video'
import { dispatchRequest } from 'systems/Request'

const VideoElement = styled.video`
  width: 100%;
`

function Video({ track }) {
  let videoRef = React.useRef(null)

  React.useEffect(() => {
    console.info('📹', 'Track attached', track)
    let currentVideoRef = videoRef.current

    track.attach(currentVideoRef)

    return () => {
      console.info('📹', 'Track detached', track)
      track.detach(currentVideoRef)
    }
  }, [track])

  return <VideoElement ref={videoRef} style={{ borderRadius: 4 }} />
}

function Room({ name }) {
  const [tracks, setTracks] = React.useState([])

  function addTrack(track) {
    console.info('📹', 'Track added', track)
    return setTracks((tracks) => [...tracks, track])
  }

  function removeTrack(track) {
    console.info('📹', 'Track removed', track)
    return setTracks((tracks) => tracks.filter((jTrack) => jTrack !== track))
  }

  useEffect(() => {
    let jRoom = null

    function participantConnected(participant) {
      console.info('📹', 'Participant "%s" connected', participant.identity)

      participant.on('trackSubscribed', addTrack)
      participant.on('trackUnsubscribed', removeTrack)

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          addTrack(publication.track)
        }
      })
    }

    dispatchRequest('getMediaToken', { room_name: name }).then((res) => {
      connect(res.video_token)
        .then((room) => {
          jRoom = room

          console.info('📹', 'Connected to Room "%s"', room.name)

          room.participants.forEach(participantConnected)
          room.on('participantConnected', participantConnected)
        })
        .catch((error) =>
          console.info('⚠️', 'Failed to connect to Twilio:', error.message)
        )
    })

    return () => {
      console.info('📹', 'Disconnected from room', jRoom)
      jRoom && jRoom.disconnect()
    }
  }, [name])

  return tracks
    .filter((track) => track.kind === 'video')
    .map((track) => <Video key={track.sid} track={track} />)
}

export default Room
