import omit from 'lodash/omit'

const updateNote = {
  method: 'patch',
  path: '/engagement/:careTeamSlug/note/:id',
  transformBody: (body) =>
    omit(body, ['author', 'engagementId', 'careTeamSlug', 'timestamp']),
}

export default updateNote
