import { useState, useCallback } from 'react'

import axios from 'axios'

import { getHeaders } from 'systems/Request'
import { parseError } from 'systems/Request'

const sendRequest = ({ method = 'get', path, query, body, blob }) =>
  axios
    .request({
      method,
      url: `${process.env.REACT_APP_API_URL.slice(0, -1)}${path}/`,
      params: query,
      headers: getHeaders(),
      data: body,
      responseType: blob ? 'blob' : null,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(parseError(error, { method, path }, { query, body }))
    })

export default sendRequest

export const useFetch = (params) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetch = useCallback(async () => {
    try {
      setLoading(true)
      setData(await sendRequest(params))
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [params])

  return { loading, data, error, fetch }
}
