import React from 'react'
import styled from 'styled-components'

import { Drawer as BDrawer, Classes } from '@blueprintjs/core'

const sizes = {
  sm: BDrawer.SIZE_SMALL,
  md: BDrawer.SIZE_STANDARD,
  lg: BDrawer.SIZE_LARGE,
}

const Wrapper = styled(BDrawer)`
  height: ${(p) =>
    p.size === BDrawer.SIZE_LARGE ? '100% !important' : 'auto'};

  .bp3-drawer-header {
    box-shadow: none;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  }
`

const Drawer = ({
  title,
  icon,
  position,
  size,
  open,
  loading,
  canEscapeKeyClose,
  canOutsideClickClose,
  onClose,
  children,
}) => (
  <Wrapper
    title={loading ? 'Please wait...' : title}
    icon={icon}
    position={position}
    size={sizes[size]}
    isOpen={open}
    isCloseButtonShown={!loading}
    canEscapeKeyClose={canEscapeKeyClose}
    canOutsideClickClose={canOutsideClickClose}
    onClose={onClose}
  >
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>{children}</div>
    </div>
  </Wrapper>
)

export default Drawer
