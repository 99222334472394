import React from 'react'
import PropTypes from 'prop-types'

import avatarPlaceholder from '_v3/core/assets/avatar-placeholder.png'
import Box from '_v3/core/views/Box'
import { useTheme } from 'hooks/useTheme'

const Avatar = ({ src, alt, size }) => {
  const { avatarSizes } = useTheme()
  return (
    <Box
      as="img"
      props={{ src: src ? src : avatarPlaceholder, alt }}
      borderRadius="50%"
      width={avatarSizes[size]}
      height={avatarSizes[size]}
    />
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md']).isRequired,
}

Avatar.defaultProps = {
  alt: 'Avatar',
  size: 'sm',
}

export default Avatar
