import { createMachine, assign, send } from 'xstate'
import { Device } from 'twilio-client'

import { sendRequest } from 'systems/Core'
import { fetch } from 'systems/Request'

const getMediaToken = fetch('getMediaToken')
const fetchUsers = fetch('getOnlineMembers')

const parseTo = (to) => {
  if (to === undefined) return btoa(undefined)
  if (to.includes('@')) return btoa(to)
  if (/^\d/.test(to)) return `+${to.replace(/[^0-9]/g, '')}`
  return to
}

const loadToken = () => {
  return getMediaToken().then((res) => {
    if (res.videoToken) {
      localStorage.setItem('video-token', res.videoToken)
    }
    if (res.voiceToken) {
      localStorage.setItem('voice-token', res.voiceToken)
    }
    return res.voiceToken
  })
}

const returnError = (err) => {
  return new Error(err.message || err.response || err || '')
}

const initDevice = () => (callback, onEvent) => {
  const device = new Device()

  onEvent((e) => {
    if (e.type === 'ACTIVATE_DEVICE') {
      try {
        getMediaToken().then((res) => {
          if (!res.voiceToken) return
          device
            .setup(res.voiceToken, {
              codecPreferences: ['opus', 'pcmu'],
              fakeLocalDTMF: true,
              closeProtection: true,
              debug: true,
            })
            .on('ready', () => {
              console.info('📳', 'READY')
              callback('READY')
            })
            .on('incoming', (conn) => {
              console.info('📳', 'INCOMING', conn)

              let { parameters, customParameters } = conn

              function getName(firstName, lastName) {
                return firstName && lastName
                  ? `${lastName}, ${firstName}`
                  : firstName || lastName || null
              }

              function createRelation() {
                let id = customParameters.get('PatientID')

                let name = getName(
                  customParameters.get('PatientFirstName'),
                  customParameters.get('PatientLastName')
                )

                let email = customParameters.get('PatientEmail')

                return { id, name, email }
              }

              function createCaller() {
                let id = customParameters.get('ID')

                let name = getName(
                  customParameters.get('FirstName'),
                  customParameters.get('LastName')
                )

                let email = customParameters.get('From')

                let relation =
                  customParameters.get('PatientProfileType') === 'patient'
                    ? createRelation()
                    : null

                let callId = customParameters.get('call')
                let originalCallId = parameters.CallSid
                let eventId = customParameters.get('event')

                return {
                  id,
                  name,
                  email,
                  relation,
                  callId,
                  originalCallId,
                  eventId,
                }
              }

              callback({ type: 'INCOMING', caller: createCaller() })
            })
            .on('cancel', () => {
              console.info('📳', 'CANCEL')
              callback('CANCEL')
            })
            .on('connect', (conn) => {
              console.info('📳', 'CONNECT', conn)
              callback({ type: 'CONNECT', callId: conn.parameters.CallSid })
            })
            .on('disconnect', () => {
              console.info('📳', 'DISCONNECT')
              callback('DISCONNECT')
            })
            .on('offline', () => {
              console.info('📳', 'OFFLINE')
              callback('OFFLINE')
            })
            .on('error', (error) => {
              console.info('📳', 'ERROR', error)
              callback({ type: 'ERROR', data: returnError(error) })
            })
        })
      } catch (error) {
        callback({ type: 'ERROR', data: returnError(error) })
      }
    }

    if (device && e.type === 'OUTGOING' && device.status() !== 'busy') {
      device.connect({
        engagement: e.engId,
        To: parseTo(e.to),
        MachineDetection: 'Enable',
        Url: process.env.REACT_APP_API_URL + 'telecom/voice/status/',
      })
    }

    if (device && e.type === 'HANG_UP') {
      device.disconnectAll()
    }

    if (device && e.type === 'ACCEPT') {
      device.activeConnection().accept()
    }

    if (device && e.type === 'REJECT') {
      device.activeConnection().reject()
    }
  })
}

const transferCall = (ctx) => {
  const callSid = ctx.caller ? ctx.caller.callId : ctx.callId
  return sendRequest({
    method: 'post',
    path: '/telecom/voice/transfer',
    body: {
      ...(callSid && { CallSid: callSid }),
      CallStatus: 'transfer',
      To: ctx.userId,
      engagement: ctx.engId,
    },
  })
}

//

const transferringState = {
  onEntry: 'setEngId',
  onExit: ['resetEngId', 'resetUser'],
  initial: 'fetchingUsers',
  states: {
    fetchingUsers: {
      initial: 'pending',
      states: {
        pending: {
          invoke: {
            src: 'fetchUsers',
            onDone: 'success',
            onError: 'failure',
          },
        },
        success: {
          type: 'final',
          onEntry: 'setUsers',
        },
        failure: {
          on: {
            RETRY: 'pending',
          },
        },
      },
      onDone: 'idle',
    },
    idle: {
      on: {
        SET_USER: {
          actions: 'setUser',
        },
        RELOAD_USERS: 'fetchingUsers',
        SUBMIT: {
          target: 'submitting',
          cond: 'hasUser',
        },
      },
    },
    submitting: {
      initial: 'pending',
      states: {
        pending: {
          invoke: {
            src: 'transferCall',
            onDone: 'success',
            onError: 'failure',
          },
        },
        success: {
          type: 'final',
        },
        failure: {
          on: {
            RETRY: 'pending',
          },
        },
      },
    },
  },
  on: {
    CANCEL_TRANSFER: 'idle',
  },
}

const connectedState = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        TRANSFER: 'transferring',
      },
    },
    transferring: transferringState,
  },
  on: {
    HANG_UP: 'disconnecting',
    DISCONNECT: 'disconnected',
  },
}

const telecomMachine = createMachine(
  {
    id: 'telecom',
    context: {
      token: null,
      caller: null,
      callId: null,
      engId: null,
      users: [],
      userId: null,
      error: null,
    },
    invoke: {
      id: 'device',
      src: 'initDevice',
    },
    initial: 'initializing',
    states: {
      initializing: {
        initial: 'loadingToken',
        states: {
          loadingToken: {
            initial: 'pending',
            states: {
              pending: {
                invoke: {
                  src: 'loadToken',
                  onDone: 'success',
                  onError: 'failure',
                },
              },
              success: {
                type: 'final',
                onEntry: 'setToken',
              },
              failure: {
                on: {
                  RETRY: 'pending',
                },
              },
            },
            onDone: 'activatingDevice',
          },
          activatingDevice: {
            initial: 'pending',
            states: {
              pending: {
                onEntry: 'activateDevice',
                on: {
                  READY: 'success',
                  ERROR: 'failure',
                },
              },
              success: {
                type: 'final',
                onEntry: 'resetToken',
              },
              failure: {
                on: {
                  RETRY: 'pending',
                },
              },
              outputNotSupported: {
                type: 'final',
              },
            },
            onDone: 'initialized',
          },
          initialized: {
            type: 'final',
          },
        },
        onDone: 'idle',
      },
      idle: {
        on: {
          INCOMING: 'incoming',
          OUTGOING: 'outgoing',
        },
      },
      incoming: {
        onEntry: 'setCaller',
        onExit: 'resetCaller',
        initial: 'idle',
        states: {
          idle: {
            on: {
              ACCEPT: 'accepted',
              REJECT: 'rejected',
              CANCEL: 'disconnected',
              // TRANSFER: 'connnected.transferring',
            },
          },
          accepted: {
            onEntry: 'accept',
            on: {
              CONNECT: 'connected',
            },
          },
          rejected: {
            type: 'final',
            onEntry: 'reject',
          },
          connected: {
            ...connectedState,
          },
          disconnecting: {
            onEntry: 'hangUp',
            on: {
              DISCONNECT: 'disconnected',
            },
          },
          disconnected: {
            type: 'final',
          },
        },
        onDone: 'idle',
      },
      outgoing: {
        initial: 'connecting',
        states: {
          connecting: {
            onEntry: 'outgoing',
            on: {
              CONNECT: 'connected',
            },
          },
          connected: {
            onEntry: 'setCallId',
            onExit: 'resetCallId',
            ...connectedState,
          },
          disconnecting: {
            onEntry: 'hangUp',
            on: {
              DISCONNECT: 'disconnected',
            },
          },
          disconnected: {
            type: 'final',
          },
        },
        onDone: 'idle',
      },
      error: {
        onEntry: 'setError',
        onExit: 'resetError',
        on: {
          DISMISS: 'idle',
        },
      },
    },
    on: {
      OFFLINE: '.initializing',
      ERROR: '.error',
    },
  },
  {
    guards: {
      hasUser: (ctx) => ctx.userId,
    },
    actions: {
      activateDevice: send(
        (ctx) => ({
          type: 'ACTIVATE_DEVICE',
          token: ctx.token,
        }),
        {
          to: 'device',
        }
      ),
      outgoing: send((_, e) => e, { to: 'device' }),
      hangUp: send('HANG_UP', { to: 'device' }),
      accept: send('ACCEPT', { to: 'device' }),
      reject: send('REJECT', { to: 'device' }),
      setToken: assign({ token: (_, e) => e.data }),
      resetToken: assign({ token: null }),
      setCaller: assign({ caller: (_, e) => e.caller }),
      resetCaller: assign({ caller: null }),
      setCallId: assign({ callId: (_, e) => e.callId }),
      resetCallId: assign({ callId: null }),
      setEngId: assign({ engId: (_, e) => e.engId }),
      resetEngId: assign({ engId: null }),
      setUsers: assign({ users: (_, e) => e.data }),
      setUser: assign({ userId: (_, e) => e.userId }),
      resetUser: assign({ userId: null }),
      setError: assign({ error: (_, e) => e.data }),
      resetError: assign({ error: null }),
    },
    services: {
      loadToken,
      initDevice,
      fetchUsers,
      transferCall,
    },
  }
)

export default telecomMachine
