import 'styled-components/macro'

import React, { createElement, useRef, useLayoutEffect } from 'react'
import { useMachine } from '@xstate/react'
import dayjs from 'dayjs'

import * as chatMachine from './chatMachine'
import { theme } from 'themeUtils'

import Col from 'systems/UI/Col'
import Row from 'systems/UI/Row'
import HSpacing from 'systems/UI/HSpacing'
import VSpacing from 'systems/UI/VSpacing'
import Avatar from 'systems/UI/Avatar'
import Text from 'systems/UI/Text'

const Chat = () => {
  const [state, send] = useMachine(chatMachine.machine)
  if (state.matches('setup')) return null

  const currentUserId = chatMachine.getCurrentUserId()(state)
  const users = chatMachine.getUsers()(state)
  const channels = chatMachine.getChannels()(state)

  const activeChannel = chatMachine.getActiveChannel()(state)
  const messages = chatMachine.getActiveChannelMessages()(state)

  return (
    <Chat.Wrapper>
      <Chat.Header
        title={activeChannel ? activeChannel.name : 'Chat'}
        collapsed={state.matches('hidden')}
        onClick={() => send('TOGGLE')}
      />
      {state.matches('visible') && (
        <Chat.ChannelList
          channels={channels}
          onSelectChannel={(channelId) =>
            send({ type: 'SELECT_CHANNEL', channelId })
          }
        />
      )}
      {state.matches('chatting.loading.failure') ? (
        <Chat.Failure />
      ) : (
        <>
          <Row>
            {state.matches('chatting') && (
              <Chat.MessageList
                currentUserId={currentUserId}
                users={users}
                messages={messages}
                loading={state.matches('chatting.loading')}
              />
            )}
            {state.matches('chatting.success') && (
              <Chat.UserList users={users} />
            )}
          </Row>
          {state.matches('chatting') && (
            <Chat.DraftMessage
              value={state.context.message}
              onChange={(value) => send({ type: 'UPDATE_MESSAGE', value })}
              onSubmit={() => send('SEND_MESSAGE')}
            />
          )}
        </>
      )}
    </Chat.Wrapper>
  )
}

Chat.Wrapper = ({ children }) => (
  <div
    css={`
      border-top-left-radius: ${theme('radii.2')};
      border-top-right-radius: ${theme('radii.2')};
      box-shadow: ${theme('shadows.2')};
      background-color: ${theme('colors.white')};
      width: 450px;
      position: fixed;
      bottom: 0;
      right: 20px;
      z-index: 99999;
      overflow: hidden;
    `}
  >
    {children}
  </div>
)

Chat.Header = ({ title, collapsed, onClick }) => (
  <button
    css={`
      border: none;
      border-bottom: 1px solid ${theme('colors.gray.2')};
      background-color: ${theme('colors.gray.0')};
      width: 100%;
      height: 30px;
      padding: 0 ${theme('spacing.2')};

      :hover {
        background-color: ${theme('colors.gray.1')};
      }

      :focus {
        outline: none;
        background-color: ${theme('colors.gray.1')};
      }
    `}
    onClick={onClick}
  >
    <Row justifyContent="space-between">
      <Text>
        <strong>{title}</strong>
      </Text>
      <Text>
        <strong>{collapsed ? <>&uarr;</> : <>&darr;</>}</strong>
      </Text>
    </Row>
  </button>
)

const Failure = ({ message = 'Something went wrong.' }) => (
  <div
    css={`
      padding: ${theme('spacing.2')};
    `}
  >
    <Text>{message}</Text>
  </div>
)

Chat.Failure = Failure

Chat.ChannelList = ({ channels, onSelectChannel }) =>
  channels.length === 0 ? (
    <div
      css={`
        padding: ${theme('spacing.2')};
      `}
    >
      <Text>No users online</Text>
    </div>
  ) : (
    <div
      css={`
        padding: ${theme('spacing.1')} 0;
        max-height: 325px;
        overflow: auto;
      `}
    >
      {channels.map(({ id, ...rest }) => (
        <Chat.ChannelItem
          key={id}
          channel={{ id, ...rest }}
          onClick={() => onSelectChannel(id)}
        />
      ))}
    </div>
  )

Chat.ChannelItem = ({ channel, onClick }) => (
  <button
    css={`
      border: none;
      background-color: transparent;
      text-align: left;
      padding: ${theme('spacing.1')} ${theme('spacing.2')};
      width: 100%;

      :hover {
        background-color: ${theme('colors.gray.1')};
      }

      :focus {
        outline: none;
        background-color: ${theme('colors.gray.1')};
      }
    `}
    onClick={onClick}
  >
    <Row alignItems="center">
      <HSpacing size={2}>
        {/* <Col>
          <Avatar alt={channel.name} />
        </Col> */}
        <Col>
          <Text>
            <strong>{channel.name}</strong>
          </Text>
          <Text size={2}>{channel.description}</Text>
        </Col>
      </HSpacing>
    </Row>
  </button>
)

const MessageList = ({ currentUserId, users, messages, loading }) => {
  const listRef = useRef()

  useLayoutEffect(() => {
    if (!listRef.current) return

    listRef.current.scrollTop = listRef.current.scrollHeight
  })

  return loading ? (
    <div
      css={`
        padding: ${theme('spacing.2')};
      `}
    >
      <Text>Loading messages...</Text>
    </div>
  ) : messages.length === 0 ? (
    <div
      css={`
        padding: ${theme('spacing.2')};
      `}
    >
      <Text>No messages yet</Text>
    </div>
  ) : (
    <div
      ref={listRef}
      css={`
        padding: ${theme('spacing.2')};
        max-height: 325px;
        flex-grow: 1;
        overflow: auto;
      `}
    >
      <VSpacing size={1}>
        {messages.map((message) =>
          createElement(
            message.userId === currentUserId
              ? Chat.OwnMessageItem
              : Chat.MessageItem,
            { key: message.id, user: users[message.userId], message }
          )
        )}
      </VSpacing>
    </div>
  )
}

Chat.MessageList = MessageList

Chat.MessageItem = ({ user, message }) => (
  <Row justifyContent="flex-start">
    <div
      css={`
        border-radius: ${theme('radii.2')};
        background-color: ${theme('colors.gray.2')};
        padding: ${theme('spacing.1')} ${theme('spacing.2')};
      `}
    >
      <Col alignItems="flex-end">
        <Text intent="secondary" size={1}>
          <strong>{user ? user.name : 'Unknown User'}</strong>
        </Text>
        <Text size={2}>{message.content}</Text>
        <Text intent="secondary" size={1}>
          {dayjs(message.timestamp).format('H:mm A')}
        </Text>
      </Col>
    </div>
  </Row>
)

Chat.OwnMessageItem = ({ message }) => (
  <Row justifyContent="flex-end">
    <div
      css={`
        border-radius: ${theme('radii.2')};
        background-color: ${theme('colors.base')};
        padding: ${theme('spacing.1')} ${theme('spacing.2')};
      `}
    >
      <Col alignItems="flex-end">
        <Text intent="light" size={2}>
          {message.content}
        </Text>
        <Text intent="light" size={1}>
          {dayjs(message.timestamp).format('H:mm A')}
        </Text>
      </Col>
    </div>
  </Row>
)

Chat.UserList = ({ users }) =>
  users.length === 0 ? null : (
    <div
      css={`
        border-left: 1px solid ${theme('colors.gray.2')};
        padding: ${theme('spacing.1')} 0;
        width: 150px;
        max-height: 325px;
        flex-shrink: 0;
        overflow: auto;
      `}
    >
      {users.map((user) => (
        <Chat.UserItem key={user.id} user={user} />
      ))}
    </div>
  )

Chat.UserItem = ({ user, onClick }) => (
  <div
    css={`
      padding: ${theme('spacing.1')} ${theme('spacing.2')};
      width: 100%;
    `}
    onClick={onClick}
  >
    <Row alignItems="center">
      <HSpacing size={2}>
        <Col>
          <Avatar alt={user.name} size={2} />
        </Col>
        <Col>
          <Text size={1}>
            <strong>{user.name}</strong>
          </Text>
          <Text intent={user.online ? 'success' : 'tertiary'} size={1}>
            &#9679; {user.online ? 'Online' : 'Offline'}
          </Text>
        </Col>
      </HSpacing>
    </Row>
  </div>
)

Chat.DraftMessage = ({ value, onChange, onSubmit }) => (
  <form
    onSubmit={(event) => {
      event.preventDefault()
      onSubmit()
    }}
  >
    <input
      value={value}
      placeholder="Type a message..."
      autoFocus
      css={`
        border: none;
        border-top: 1px solid ${theme('colors.gray.2')};
        outline: none;
        font-size: ${theme('fontSizes.2')};
        width: 100%;
        padding: ${theme('spacing.2')};
      `}
      onChange={(event) => onChange(event.target.value)}
    />
  </form>
)

export default Chat
