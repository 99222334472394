const getInstituteMembers = {
  method: 'get',
  path: '/institute/:instituteId/member',
  transformData: (data) => {
    return {
      ...data,
      results: data.results.map(
        ({
          id,
          username,
          first_name: firstName,
          last_name: lastName,
          profile_type: roles,
          phone_number: phoneNumber,
          mobile_number: mobileNumber,
          disabled,
        }) => {
          const _roles = roles
            ? roles.map(({ profile_type }) => profile_type)
            : []
          return {
            id,
            username,
            firstName,
            lastName,
            fullName: `${lastName}, ${firstName}`,
            phoneNumber,
            mobileNumber,
            isManager: _roles.indexOf('manager') !== -1,
            isCoordinator: _roles.indexOf('coordinator') !== -1,
            isPhysician: _roles.indexOf('physician') !== -1,
            isNurse: _roles.indexOf('nurse') !== -1,
            isInstituteNurse: _roles.indexOf('institute_nurse') !== -1,
            isAdvisor: _roles.indexOf('advisor') !== -1,
            isCounselor: _roles.indexOf('counselor') !== -1,
            isPatient: _roles.indexOf('patient') !== -1,
            isActive: !disabled,
            disabled,
          }
        }
      ),
    }
  },
}

export default getInstituteMembers
