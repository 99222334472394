import { getRequest } from 'systems/Request'
import omit from 'lodash/omit'
import { getPathParams } from 'systems/Request'

const getQuery = (request, params) => {
  const { method, transformQuery = (query) => query } = getRequest(request)
  return method === 'get'
    ? transformQuery(omit(params, Object.keys(getPathParams(request, params))))
    : null
}

export default getQuery
