import axios from 'axios'
import { getBody } from 'systems/Request'
import { getHeaders } from 'systems/Request'
import { getPath } from 'systems/Request'
import { getQuery } from 'systems/Request'
import { getRequest } from 'systems/Request'
import logout from 'utils/logout'

const _axios = axios.create()

function isStatus(err, num) {
  return err.response && err.response.status === num
}

function returnError(err) {
  return new Error(err.message || err.response || err || '')
}

const dispatchRequest = async (request, params) => {
  const {
    method,
    transformData = (data) => data,
    transformError = ({ error }) => error,
    responseType,
  } = getRequest(request)

  let parsedPath = null
  try {
    parsedPath = getPath(request, params)
  } catch (error) {
    parsedPath = null
  }

  const queryParams = getQuery(request, params)

  for (let i = 1; i <= 4; i++) {
    try {
      const response = await _axios.request({
        method,
        url: `${process.env.REACT_APP_API_URL.slice(0, -1)}${parsedPath}/`,
        params: queryParams,
        headers: getHeaders(),
        data: getBody(request, params),
        responseType,
      })

      return transformData(response.data)
    } catch (error) {
      const tError = transformError({ error, params })

      // 400 errors should skip the retry system
      if (isStatus(tError, 400)) {
        return returnError(tError)
      }

      if (request === 'getUser' && isStatus(tError, 401)) {
        logout()
        return returnError(tError)
      }

      // When API returns 404, it means the resource doesn't exists.
      // In that case we need to return null to be able to either dispatch a
      // POST or PUT/PATCH request
      if (isStatus(tError, 404) || isStatus(tError, 403)) {
        return returnError(tError)
      }

      await new Promise((resolve) => setTimeout(resolve, 2500))
      console.info(`${parsedPath} failed, retrying (${i})...`)

      if (i === 4) {
        console.info(`All retries failed for ${parsedPath}.`)
        return returnError(tError)
      }
    }
  }
}

export default dispatchRequest
