import { createMachine, assign } from 'xstate'
import { dispatchRequest } from 'systems/Request'

const userMachine = createMachine(
  {
    id: 'user',
    context: {
      roles: [],
    },
    initial: 'pending',
    states: {
      pending: {
        invoke: {
          src: 'getUser',
          onDone: 'success',
          onError: 'failure',
        },
      },
      success: {
        onEntry: 'setUser',
      },
      failure: {
        after: {
          2000: 'pending',
        },
      },
    },
  },
  {
    actions: {
      setUser: assign((_, e) => e.data),
    },
    services: {
      getUser: () => dispatchRequest('getUser'),
    },
  }
)

export default userMachine
