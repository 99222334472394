import { useState, useCallback } from 'react'

const useAsync = (factory, onDone, onError) => {
  const [state, setState] = useState({
    value: null,
    error: null,
    pristine: true,
    loading: false,
  })

  const dispatch = useCallback(() => {
    setState((state) => ({
      ...state,
      pristine: false,
      loading: true,
      error: null,
    }))

    factory().then(
      (value) => {
        setState((state) => ({
          ...state,
          value,
          loading: false,
        }))
        onDone && onDone(value)
      },
      (error) => {
        setState((state) => ({
          ...state,
          error,
          loading: false,
        }))
        onError && onError(error)
      }
    )
  }, [factory, onDone, onError])

  return { ...state, dispatch }
}

export default useAsync
