import createStore from '_v3/core/utils/createStore'

const SessionStore = createStore({
  displayName: 'SessionStore',
  initialState: {
    token: null,
    userId: null,
    instituteId: null,
    careTeamId: null,
  },
  driver: localStorage,
})

export default SessionStore
