import dayjs from 'dayjs'

const formatsBySize = {
  xs: 'MM/DD HH:mm',
  sm: 'MM/DD/YY HH:mm',
  md: 'MM/DD/YYYY HH:mm',
}

const formatDateTime = (dateTime, size = 'sm') =>
  dayjs(dateTime).format(formatsBySize[size])

export default formatDateTime
