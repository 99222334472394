import capitalize from 'lodash/capitalize'

const getLatestMeasurements = {
  method: 'get',
  path: '/readings/:username/latest',
  transformData: (data) =>
    data.map(({ id, timestamp, type, value, unit, level, goal }) => ({
      id,
      timestamp,
      type: type.split('_').map(capitalize).join(' '),
      rawType: type,
      value,
      unit,
      level: level ? level[0] : 1,
      status: level ? level[1] : '-',
      goal,
    })),
}

export default getLatestMeasurements
