import React, { Component } from 'react'

import LocalStorage from 'components/LocalStorage'
import PrivateRoutes from 'components/PrivateRoutes'
import PublicRoutes from 'components/PublicRoutes'
import Chat from 'systems/Chat'

import { UserContextController } from 'systems/Auth'
import { TelecomContextController } from 'systems/Telecom'
import TermsAndConditions from 'systems/TermsAndConditions'

class Routes extends Component {
  render() {
    return (
      <LocalStorage subscribeTo="token">
        {({ getItem }) =>
          getItem('token') ? (
            <>
              <UserContextController>
                <TelecomContextController>
                  <PrivateRoutes />
                  <TermsAndConditions />
                </TelecomContextController>
              </UserContextController>
              <Chat />
            </>
          ) : (
            <PublicRoutes />
          )
        }
      </LocalStorage>
    )
  }
}

export default Routes
