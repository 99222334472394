import * as R from 'ramda'
import { useContext } from 'react'

import { useHTTPService } from 'hooks/useHTTPService'
import { useInstituteService } from 'hooks/useInstituteService'
import { useUserService } from 'hooks/useUserService'
import SessionStore from '_v3/core/stores/SessionStore'

const logoutRequest = () => ({
  method: 'post',
  path: '/auth/logout',
})

export const useSessionService = () => {
  const { getState, setState } = useContext(SessionStore.context)
  const { sendRequest } = useHTTPService()
  const { getInstitute } = useInstituteService()
  const { loadCurrentUser } = useUserService()

  const getToken = () => localStorage.token // getState().token
  const getInstituteId = () => getState().instituteId
  const getCareTeamId = () => getState().careTeamId
  const setUserId = (userId) => setState({ userId })

  const setInstituteId = async (instituteId) => {
    await setState({ instituteId })

    const {
      careTeamIds: [firstCareTeamId],
    } = await getInstitute(instituteId)
    await setCareTeamId(firstCareTeamId)

    try {
      window.Sentry.configureScope((scope) => {
        scope.setTag('institute', getInstituteId())
        scope.setTag('careTeam', getCareTeamId())
      })
    } catch (error) {
      //
    }

    return null
  }

  const setCareTeamId = (careTeamId) =>
    setState({ careTeamId }).then((state) => {
      try {
        window.Sentry.configureScope((scope) =>
          scope.setTag('careTeam', getCareTeamId())
        )
      } catch (error) {
        //
      } finally {
        return state
      }
    })

  const resetToken = () => setState({ token: null })
  const resetUserId = () => setState({ userId: null })

  const loginWithToken = async () => {
    if (R.isNil(getToken())) return Promise.reject()
    await startSession()
    return null
  }

  const startSession = async () => {
    const {
      id: userId,
      instituteIds: [firstInstituteId],
      ...rest
    } = await loadCurrentUser()

    try {
      const {
        id: instituteId,
        careTeamIds: [firstCareTeamId],
      } = await getInstitute(firstInstituteId)

      await setUserId(userId)
      if (!getInstituteId()) await setInstituteId(instituteId)
      if (!getCareTeamId()) await setCareTeamId(firstCareTeamId)

      localStorage.setItem('institute-id', instituteId)
    } catch (error) {
      alert('This user has no institute. Please contact the administrator.')
      logout()
    } finally {
      try {
        window.Sentry.configureScope((scope) => {
          scope.setUser({ id: userId, roles: rest.roles })
          scope.setTag('institute', getInstituteId())
          scope.setTag('careTeam', getCareTeamId())
        })
      } catch (error) {
        //
      }

      return null
    }
  }

  const logout = async () => {
    await sendRequest(logoutRequest())
    await resetToken()
    await resetUserId()
    localStorage.removeItem('institute-id')
    return null
  }

  return {
    ...getState(),
    setUserId,
    setInstituteId,
    setCareTeamId,
    resetUserId,
    loginWithToken,
    logout,
  }
}
