const getInstituteProfile = {
  method: 'get',
  path: '/institute/:instituteId/profile',
  transformData: ({
    id,
    institute,
    phone_number: phone,
    after_number: after,
    fax_number: fax,
    ein,
    npi,
  }) => ({
    id,
    institute,
    phone,
    after,
    fax,
    ein,
    npi,
  }),
}

export default getInstituteProfile
