import createStore from '_v3/core/utils/createStore'

import theme from '_v3/theme'

const ThemeStore = createStore({
  displayName: 'ThemeStore',
  initialState: theme,
})

export default ThemeStore
