import { interpret } from 'xstate'
import { useEffect, useState } from 'react'

export function createGlobalMachine(machine) {
  const service = interpret(machine).start()
  const map = new Map()
  map.set('value', service.state)
  return { service, map }
}

export function useGlobalMachine({ service, map }) {
  const [state, set] = useState(() => map.get('value'))
  useEffect(() => {
    const sub = service.subscribe((current) => {
      if (current.changed) {
        map.set('value', current)
        set(map.get('value'))
      }
    })
    return () => sub.unsubscribe()
    //eslint-disable-next-line
  }, [])
  return [state, service.send]
}
