const getCareTeams = {
  method: 'get',
  path: '/institute/:instituteId/care_teams',
  transformData: (data) => {
    return data.results.map(
      ({ id, slug, institute, care_team, visible, members }) => ({
        id,
        slug,
        instituteId: institute,
        name: care_team,
        managerCount: members.managers,
        coordinatorCount: members.coordinators,
        physicianCount: members.physicians,
        nurseCount: members.nurses,
        advisorCount: members.advisors,
        patientCount: members.patients,
        visible,
      })
    )
  },
}

export default getCareTeams
