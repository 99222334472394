import React, { useContext } from 'react'
import { Match } from '@reach/router'

import NavBarItem from '_v3/core/views/NavBarItem'
import Spinner from 'components/Spinner'

import { Popover } from 'systems/Core'
import { UserContext } from 'systems/Auth'
import {
  TelecomContext,
  Call,
  telecomSelectors,
  telecomEvents,
} from 'systems/Telecom'

const CallNavBarItem = () => {
  const user = useContext(UserContext)
  const patient = user.state.context.roles.includes('patient')
  const telecom = useContext(TelecomContext)

  return (
    <Match path="/events/:id/*">
      {(props) =>
        props.match ? null : (
          <Popover
            boundary="viewport"
            content={
              <Call
                callerName={telecom.select(telecomSelectors.getCallerName)}
                error={
                  telecom.select(telecomSelectors.hasInitializationError)
                    ? telecom.state.context.error || 'Failed to connect'
                    : telecom.select(telecomSelectors.getErrorMessage)
                }
                enableEventAssignment={
                  !patient && telecom.select(telecomSelectors.isConnected)
                }
                eventId={
                  !patient && telecom.state.context.caller
                    ? telecom.state.context.caller.eventId
                    : null
                }
                onAccept={
                  telecom.select(telecomSelectors.isAwaitingResponse)
                    ? () => telecom.send(telecomEvents.accept())
                    : null
                }
                onReject={
                  telecom.select(telecomSelectors.isAwaitingResponse)
                    ? () => telecom.send(telecomEvents.reject())
                    : null
                }
                onDisconnect={
                  telecom.select(telecomSelectors.isConnected)
                    ? () => telecom.send(telecomEvents.hangUp())
                    : null
                }
                onRetry={
                  telecom.select(telecomSelectors.hasInitializationError)
                    ? () => telecom.send(telecomEvents.retry())
                    : null
                }
                onDismiss={
                  telecom.select(telecomSelectors.getErrorMessage)
                    ? () => telecom.send(telecomEvents.dismiss())
                    : null
                }
              />
            }
            open={
              !(
                (telecom.select(telecomSelectors.isInitializing) &&
                  !telecom.select(telecomSelectors.hasInitializationError)) ||
                telecom.select(telecomSelectors.isIdle) ||
                telecom.select(telecomSelectors.hasPendingWork)
              )
            }
          >
            <NavBarItem
              leftIcon="phone"
              label={
                telecom.select(telecomSelectors.hasPendingWork) ? (
                  <Spinner />
                ) : patient && telecom.select(telecomSelectors.isIdle) ? (
                  'Call PEA'
                ) : telecom.select(telecomSelectors.isIncoming) ? (
                  'Incoming Call'
                ) : telecom.select(telecomSelectors.isConnected) ? (
                  'Active Call'
                ) : null
              }
              onClick={
                patient && telecom.select(telecomSelectors.isIdle)
                  ? () => telecom.send(telecomEvents.outgoing())
                  : null
              }
            />
          </Popover>
        )
      }
    </Match>
  )
}

export default CallNavBarItem
