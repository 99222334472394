import upperFirst from 'lodash/upperFirst'

export const getUserRoles = {
  method: 'get',
  path: '/personal/:username/types',
  transformData: (data) =>
    data.results.map(({ id, profile_type: name }) => ({
      id,
      name: String(name)
        .replace(/_/g, ' ')
        .split(' ')
        .map(upperFirst)
        .join(' '),
      rawName: name,
    })),
}

export default getUserRoles
