import React from 'react'
import PropTypes from 'prop-types'
import View from 'components/View'

const Icon = ({
  color,
  name,
  size,
  marginLeft,
  marginRight,
  className,
  ...rest
}) => (
  <View
    className={className}
    color={color}
    display="inline-flex"
    justifyContent={marginLeft ? 'flex-end' : null}
    width={marginLeft || marginRight ? 12 + 8 * size : null}
  >
    <View
      {...rest}
      className={`fa fa-${name} ${
        size === 1 ? '' : size === 2 ? 'fa-lg' : `fa-${size}`
      }`}
      component="span"
      display="inline-block"
    />
  </View>
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
}

Icon.defaultProps = {
  size: 1,
}

export default Icon
