import { schema } from 'normalizr'

import { CARE_TEAM_ENTITY } from '_v3/care-team/constants'

const careTeamSchema = new schema.Entity(
  CARE_TEAM_ENTITY,
  {},
  {
    idAttribute: 'slug',
    processStrategy: ({ slug, care_team }) => ({
      id: slug,
      slug,
      name: care_team,
    }),
  }
)

export default careTeamSchema
