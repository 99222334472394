import * as R from 'ramda'
import { toaster, Intent } from 'systems/Notification'

export const handleError = (error) => {
  if (error && error.response && error.response.status === 400) {
    const values = R.values(error.response.data)
    if (!Boolean(values.length)) return
    const message = Array.isArray(values[0]) ? values[0][0] : values[0]

    if (typeof message === 'string') {
      toaster.show({ message, intent: Intent.DANGER })
      return
    }

    R.toPairs(message)
      .map(([key, msg]) => `${key}: ${msg}`)
      .forEach((message) => {
        toaster.show({ message, intent: Intent.DANGER })
      })
  }
}
