import React from 'react'

import { Popover as JPopover, Classes } from '@blueprintjs/core'

const Popover = ({ boundary, position, content, open, children }) => (
  <JPopover
    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
    boundary={boundary}
    position={position}
    content={content}
    isOpen={open}
  >
    {children}
  </JPopover>
)

export default Popover
