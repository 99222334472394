import React from 'react'
import PropTypes from 'prop-types'

import Box from '_v3/core/views/Box'

const HorizontalSpacer = ({ size }) => <Box width={size} flexShrink={0} />

HorizontalSpacer.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'])
    .isRequired,
}

export default HorizontalSpacer
