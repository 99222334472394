import React from 'react'
import PropTypes from 'prop-types'

import Box from '_v3/core/views/Box'
import { useTheme } from 'hooks/useTheme'

const Container = ({ flexGrow, size, children }) => {
  const { containerSizes } = useTheme()
  return (
    <Box
      width="100%"
      maxWidth={containerSizes[size]}
      margin="0 auto"
      horizontalPadding="lg"
      flexGrow={flexGrow}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  flexGrow: PropTypes.number,
  size: PropTypes.oneOf(['sm', 'lg']).isRequired,
  children: PropTypes.node,
}

export default Container
