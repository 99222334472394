import 'react-datepicker/dist/react-datepicker.css'

import React from 'react'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'

const MonthPicker = ({ value = new Date(), disabled, onChange = () => {} }) => (
  <DatePicker
    dateFormat="MM/yyyy"
    selected={dayjs(value).toDate()}
    disabled={disabled}
    showMonthYearPicker
    onChange={(value) => onChange(value)}
  />
)

export default MonthPicker
