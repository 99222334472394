import React from 'react'
import View from 'components/View'

const Spinner = ({ className, ...props }) => (
  <View
    className={`${className} fa fa-spinner fa-spin ${
      props.size ? `fa-${props.size}` : ''
    }`}
    component="span"
    display="inline-block"
    {...props}
  />
)

export default Spinner
