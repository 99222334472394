import React from 'react'
import PropTypes from 'prop-types'

import NAVBAR_HEIGHT from 'constants/NAVBAR_HEIGHT'
import View from 'components/View'
import SPACING from 'constants/SPACING'
import Spinner from 'components/Spinner'

const Backdrop = ({
  maxContentWidth = '650px',
  fullScreen = false,
  isLoading,
  onClose,
  children,
}) => {
  return (
    <View
      backgroundColor="rgba(0, 0, 0, 0.5)"
      color="#404040"
      position="fixed"
      top={0}
      left={0}
      zIndex={1001}
      width="100%"
      height="100%"
      overflow={fullScreen ? null : 'auto'}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <View
        position="relative"
        top={0}
        width={fullScreen ? '85vw' : '100%'}
        height={fullScreen ? '85vh' : null}
        maxWidth={fullScreen ? null : maxContentWidth}
        margin={
          fullScreen ? null : `${NAVBAR_HEIGHT + 5 + SPACING * 1.25 * 2}px auto`
        }
        overflow="auto"
      >
        {onClose && (
          <View
            props={{ onClick: isLoading ? null : onClose }}
            borderBottom="2px solid #e6e6e6"
            borderLeft="2px solid #e6e6e6"
            backgroundColor="#f2f2f2"
            fontSize={isLoading ? null : 30}
            lineHeight={1}
            position="absolute"
            top={0}
            right={0}
            zIndex={1}
            paddingTop={isLoading ? 4 : -2}
            paddingBottom={4}
            paddingLeft={isLoading ? 4 : 8}
            paddingRight={isLoading ? 4 : 7}
            cursor="pointer"
          >
            {isLoading ? <Spinner /> : <span>&times;</span>}
          </View>
        )}
        {fullScreen ? (
          children
        ) : (
          <View width="100%" maxWidth={maxContentWidth}>
            {children}
          </View>
        )}
      </View>
    </View>
  )
}

Backdrop.propTypes = {
  maxContentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Backdrop
