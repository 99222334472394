import React, { Fragment } from 'react'
import { BsCardList, BsCheck, BsX, BsCalendar } from 'react-icons/bs'
import styled from 'styled-components'
import dayjs from 'dayjs'

import {
  Popover,
  Position,
  Button,
  PopoverInteractionKind,
  Intent,
} from '@blueprintjs/core'

import COLORS from 'constants/COLORS'
import View from 'components/View'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 15px 20px;

  h3 {
    font-size: 11px;
    text-transform: uppercase;
    color: ${COLORS.darkGray4};
    margin-bottom: 10px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid ${COLORS.gray4};
  }
`

const ReservationItem = styled.div`
  display: flex;
  align-items: center;

  & ~ & {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid ${COLORS.gray4};
  }

  .name {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${COLORS.darkGray2};
  }

  .name svg {
    margin-right: 10px;
  }
`

const ReservationStatus = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: ${(p) => p.color};
  margin-right: 10px;
`

const statuses = {
  accepted: COLORS.green2,
  canceled: COLORS.red2,
  pending: COLORS.gray4,
}

export const ReservationList = ({ reservations = [] }) => {
  const content = (
    <Content>
      <h3>Reservation List</h3>
      {!Boolean(reservations.length) && <div>No reservation found</div>}
      {reservations.length > 0 &&
        reservations.map((item) => {
          const status = item.reservationStatus
          return (
            <ReservationItem key={item.sid}>
              <ReservationStatus color={statuses[status]} />
              <div className="name">
                <BsCalendar size={18} color={COLORS.darkGray4} />
                {dayjs(item.dateCreated).utc().format('MM-DD-YYYY hh:mm')}
              </div>
              {status !== 'accepted' && status !== 'canceled' && (
                <Fragment>
                  <Button
                    small
                    minimal
                    intent={Intent.SUCCESS}
                    icon={<BsCheck size={18} />}
                    onClick={() => item.accept()}
                  />
                  <Button
                    small
                    minimal
                    intent={Intent.DANGER}
                    icon={<BsX size={18} />}
                    onClick={() => item.reject()}
                  />
                </Fragment>
              )}
            </ReservationItem>
          )
        })}
    </Content>
  )

  return (
    <View marginLeft={20}>
      <Popover
        content={content}
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM}
      >
        <Button minimal outlined>
          <BsCardList size={17} color={COLORS.darkGray2} />
        </Button>
      </Popover>
    </View>
  )
}
