import { useQuery, QueryConfig } from 'react-query'
import { fetch } from 'systems/Request'

const QUERY = 'getUser'

export default function useUser(queryOptions: QueryConfig<any, any> = {}) {
  const { data, status } = useQuery(QUERY, fetch(QUERY), queryOptions)
  return {
    user: data,
    isLoading: status === 'loading',
    isError: status === 'error',
  }
}
