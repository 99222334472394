import { Path } from 'path-parser'
import { getRequest } from 'systems/Request'
import { getPath } from 'systems/Request'

const getPathParams = (request, params) => {
  const { path } = getRequest(request)
  return Path.createPath(path).test(getPath(request, params))
}

export default getPathParams
