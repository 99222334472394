const passwordlessLogin = {
  method: 'post',
  path: '/auth/token',
  transformError: ({ error }) =>
    error.response && error.response.status === 404
      ? {
          ...error,
          response: {
            ...error.response,
            status: 400,
          },
        }
      : error,
}

export default passwordlessLogin
