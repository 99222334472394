import get from 'lodash/get'

import { CARE_TEAM_ENTITY } from '_v3/care-team/constants'
import { INSTITUTE_ENTITY } from '_v3/institute/constants'
import { useEntityService } from 'hooks/useEntityService'

export const useInstituteService = () => {
  const { getEntities, getEntity } = useEntityService()

  const getCareTeam = (careTeamId) => getEntity(CARE_TEAM_ENTITY, careTeamId)

  const getInstitutes = () =>
    getEntities(INSTITUTE_ENTITY, { isFallbackEnabled: true })

  const getInstitute = (instituteId) => getEntity(INSTITUTE_ENTITY, instituteId)

  const getCareTeams = (instituteId) =>
    get(getInstitute(instituteId), 'careTeamIds', []).map(getCareTeam)

  const hasCareTeams = (instituteId) =>
    get(getInstitute(instituteId), 'careTeamIds', []).length > 0

  return {
    getInstitutes,
    getInstitute,
    getCareTeams,
    hasCareTeams,
  }
}
