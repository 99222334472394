import React from 'react'

import 'styled-components/macro'

const Col = ({ span, justifyContent, alignItems, flexGrow, children }) => (
  <div
    css={`
      display: flex;
      flex-direction: column;
      justify-content: ${justifyContent};
      align-items: ${alignItems};
      flex-grow: ${flexGrow};
      width: ${span ? `${(span / 12) * 100}%` : null};
    `}
  >
    {children}
  </div>
)

export default Col
