import upperFirst from 'lodash/upperFirst'

export const getUserRelations = {
  method: 'get',
  path: '/personal/:username/relation_type',
  transformData: (data) =>
    data.results.map(({ id, relation_type: name }) => ({
      id,
      name: upperFirst(name),
      rawName: name,
    })),
}

export default getUserRelations
