const updateInstituteProfile = {
  method: 'patch',
  path: '/institute/:instituteId/profile',
  transformBody: (body) => {
    const {
      id,
      instituteId: slug,
      institute,
      phone: phone_number,
      after: after_number,
      fax: fax_number,
      ein,
      npi,
    } = body

    return {
      id,
      slug,
      institute,
      phone_number,
      after_number,
      fax_number,
      ein,
      npi,
    }
  },
}

export default updateInstituteProfile
