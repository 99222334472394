import React, { Children, isValidElement, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Block } from 'jsxstyle'
import SPACING from 'constants/SPACING'
import Media from 'react-media'

const Grid = React.forwardRef(
  ({ alignment, isSmall, children, ...rest }, ref) => {
    const spacing = SPACING * (isSmall ? 0.75 : 1)
    return (
      <Block
        ref={ref}
        display="flex"
        flexWrap="wrap"
        justifyContent={alignment === 'center' ? 'center' : null}
        marginBottom={-spacing}
        marginRight={-spacing}
        {...rest}
      >
        {Children.map(children, (child) =>
          isValidElement(child) && child.type === Col
            ? cloneElement(child, {
                alignment: child.props.alignment || alignment,
                isSmall: child.props.isSmall || isSmall,
              })
            : child
        )}
      </Block>
    )
  }
)

Grid.propTypes = {
  alignment: PropTypes.oneOf(['center']),
  isSmall: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Grid

//

const Col = ({ alignment, size, isSmall, children }) => {
  const spacing = SPACING * (isSmall ? 0.75 : 1)
  return (
    <Media query={{ minWidth: 800 }}>
      {(matches) => (
        <Block
          textAlign={alignment || null}
          width={matches ? `${(size / 12) * 100}%` : '100%'}
          position="relative"
          paddingBottom={spacing}
          paddingRight={spacing}
        >
          {children}
        </Block>
      )}
    </Media>
  )
}

Col.propTypes = {
  alignment: PropTypes.oneOf(['center', 'right']),
  size: PropTypes.number,
  rightBottom: PropTypes.node,
  children: PropTypes.node,
}

Col.defaultProps = {
  size: 12,
}

export { Col }

//

const RightBottom = ({ isSmall, children }) => {
  const spacing = SPACING * (isSmall ? 0.75 : 1)
  return (
    <Block position="absolute" bottom={spacing} right={spacing}>
      {children}
    </Block>
  )
}

RightBottom.propTypes = {
  isSmall: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export { RightBottom }

//

const TopRight = ({ isSmall, children, ...rest }) => {
  const spacing = SPACING * (isSmall ? 0.75 : 1)
  return (
    <Block position="absolute" top={spacing} right={spacing} {...rest}>
      {children}
    </Block>
  )
}

TopRight.propTypes = {
  isSmall: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export { TopRight }
