import React from 'react'

import LegacyButton from 'components/Button'

const Button = ({ intent, loading, disabled, onClick, children }) => (
  <LegacyButton
    intent={intent}
    isLoading={loading}
    isDisabled={disabled}
    onClick={onClick}
  >
    {children}
  </LegacyButton>
)

export default Button
