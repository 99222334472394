const captureError = (error) => {
  if (process.env.REACT_APP_DISABLE_CRASH_REPORTING) return true
  try {
    window.Sentry.captureMessage(
      typeof error === 'string' ? error : 'Unknown error'
    )
  } catch (err) {
    console.log(err)
  }
  return true
}

const parseError = (error, { method, path }, params) => {
  // 1. Let's get the status code from the error response.
  const status = error && error.response ? error.response.status : null

  // 2. If status is 502, it means it's a server error, and we can't do anything about it.
  if (status === 502)
    return 'The server is offline. Please try again in a couple minutes.'

  // 3. Let's also get any data that the error response returned.
  const data = error && error.response && error.response.data

  // 4. If we have data and it has a `detail` property we can safely return it.
  if (data && data.detail) return data.detail

  // 5. If we have data and it has a `message` property we can safely return it.
  if (data && data.message) return data.message

  // 6. If we have data and it has a `non_field_errors`, we can safely return the first item in the array.
  if (data && data.non_field_errors && data.non_field_errors[0])
    return data.non_field_errors[0]

  // 7. If we have data and we failed to get a proper error message at this point,
  // it means that we don't know the name of the property that contains the error messsage,
  // so we'll get the the first property returned and try to parse a string from it.
  if (data) {
    const keys = Object.keys(data)
    const firstKey = keys[0]
    const firstError = data[firstKey]
    const firstErrorMessage = Array.isArray(firstError)
      ? firstError[0]
      : typeof firstError === 'string'
      ? firstError
      : null
    if (firstErrorMessage && firstErrorMessage.length > 5)
      return firstErrorMessage
  }

  // 8. If we still didn't get a proper error message, maybe it was a script error.
  // So we'll send the error to Sentry.
  const errorDetails = {
    request: { method, path, params, token: localStorage.token },
    error,
  }
  const didCapture = captureError(error, errorDetails)

  // 9. In development the error is not sent to Sentry, so we'll log it in the user console.
  if (!didCapture) {
    console.error(errorDetails)
    return 'Please check your console.'
  }

  return null
}

export default parseError
