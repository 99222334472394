import React from 'react'
import { navigate } from '@reach/router'

import NavBarItem from '_v3/core/views/NavBarItem'
import Spinner from 'components/Spinner'
import useUser from 'hooks/useUser'
import { Position } from '@blueprintjs/core'

const renderNavbar = (items) => {
  return (
    <NavBarItem
      label="Administration"
      leftIcon="cog"
      dropdownPlacement={Position.BOTTOM}
      dropdownItems={items}
    />
  )
}

const AdministrationNavBarItem = ({ location }) => {
  const { user, isLoading } = useUser()

  if (isLoading) {
    return <Spinner />
  }

  if (user?.isAdmin) {
    return renderNavbar([
      {
        label: 'Institute',
        leftIcon: 'building',
        isActive: !!location.pathname.match('/institute'),
        onClick: () => navigate('/institute'),
      },
      {
        label: 'Patients',
        leftIcon: 'user',
        isActive: !!location.pathname.match('/patients'),
        onClick: () => navigate('/patients'),
      },
    ])
  }

  if (user?.isEducationAdmin) {
    return renderNavbar([
      {
        label: 'Education',
        leftIcon: 'graduation-cap',
        isActive: !!location.pathname.match('/education-admin'),
        onClick: () => navigate('/education-admin'),
      },
    ])
  }

  if (user?.isSurveyAdmin) {
    return renderNavbar([
      {
        label: 'Survey',
        leftIcon: 'list-ul',
        isActive: !!location.pathname.match('/survey-admin'),
        onClick: () => navigate('/survey-admin'),
      },
    ])
  }

  return null
}

export default AdministrationNavBarItem
