const COLORS = {
  white: '#fff',
  gray1: '#ccc',
  gray2: '#d9d9d9',
  gray3: '#e6e6e6',
  gray4: '#eee',
  gray5: '#f2f2f2',
  darkGray1: '#404040',
  darkGray2: '#595959',
  darkGray3: '#737373',
  darkGray4: '#8c8c8c',
  darkGray5: '#a6a6a6',
  red1: '#862d2d',
  red2: '#993333',
  red3: '#ac3939',
  green1: '#2d8659',
  green2: '#339966',
  green3: '#39ac73',
  darkGreen1: '#8ba12b',
  darkGreen2: '#aec936',
  darkGreen3: '#bfd45f',
  blue1: '#3d7db0',
  blue2: '#4186be',
  blue3: '#5492c4',
  blue4: '#65676c',
  lightBlue1: '#F2F5FD',
  lightGray: '#A7B6C2',
  orange1: '#f4a533',
}

export default COLORS
