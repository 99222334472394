import * as R from 'ramda'

const getDailyAdvisorPerformanceReport = {
  method: 'post',
  responseType: 'blob',
  path: '/reporting/:institute/daily_performance',
  transformBody: R.omit(['institute', 'instituteId']),
}

export default getDailyAdvisorPerformanceReport
