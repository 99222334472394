const createUserPhoto = {
  method: 'post',
  path: '/personal/:username/photo',
  transformBody: ({ profileId: profile, photo }) => ({
    profile,
    photo,
    active: true,
  }),
}

export default createUserPhoto
