import flatten from 'lodash/flatten'

import { schema } from 'normalizr'

import { USER_ENTITY } from '_v3/user/constants'

import userTitleSchema from '_v3/user/schemas/userTitleSchema'
import userRoleSchema from '_v3/user/schemas/userRoleSchema'
import instituteSchema from '_v3/institute/schemas/instituteSchema'
import careTeamSchema from '_v3/care-team/schemas/careTeamSchema'

const userSchema = new schema.Entity(
  USER_ENTITY,
  {
    titleId: userTitleSchema,
    roleIds: [userRoleSchema],
    instituteIds: [instituteSchema],
    careTeamIds: [careTeamSchema],
  },
  {
    processStrategy: ({
      id,
      username,
      title,
      first_name,
      last_name,
      initials,
      email,
      institute,
      terms_and_conditions,
      profile_type,
    }) => ({
      id,
      username,
      titleId: title,
      firstName: first_name,
      lastName: last_name,
      fullName: `${last_name}, ${first_name}`,
      initials,
      email,
      termsAcceptedAt: terms_and_conditions,
      roles: Array.isArray(profile_type)
        ? profile_type.map((type) => type.profile_type)
        : profile_type,
      roleIds: Array.isArray(profile_type) ? profile_type : [],
      ...(Array.isArray(institute)
        ? {
            instituteIds: institute,
            careTeamIds: flatten(institute.map(({ care_team }) => care_team)),
          }
        : {
            instituteIds: [],
            careTeamIds: [],
          }),
    }),
  }
)

export default userSchema
