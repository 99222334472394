import upperFirst from 'lodash/upperFirst'

import { schema } from 'normalizr'

import { USER_TITLE_ENTITY } from '_v3/user/constants'

const userTitleSchema = new schema.Entity(
  USER_TITLE_ENTITY,
  {},
  {
    processStrategy: ({ id, title }) => ({
      id,
      slug: title,
      name: upperFirst(title),
    }),
  }
)

export default userTitleSchema
