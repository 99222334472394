import React, { Component, createContext } from 'react'

const createStore = ({
  displayName = 'Store',
  initialState = {},
  driver = null,
}) => {
  const ctx = createContext()

  return {
    context: ctx,
    Provider: class extends Component {
      static displayName = `${displayName}.Provider`

      getState = () => this.state

      _setState = (nextState) =>
        new Promise((resolve) =>
          this.setState(nextState, () => {
            driver &&
              driver.setItem(displayName, JSON.stringify(this.getState()))
            resolve()
          })
        )

      state = {
        ...((driver && JSON.parse(driver.getItem(displayName))) ||
          initialState),
        getState: this.getState,
        setState: this._setState,
      }

      render() {
        return (
          <ctx.Provider value={this.state}>{this.props.children}</ctx.Provider>
        )
      }
    },
    Consumer: class extends Component {
      static displayName = `${displayName}.Consumer`

      render() {
        const { children, ...rest } = this.props
        return (
          <ctx.Consumer {...rest}>
            {(props) => {
              if (props === undefined)
                throw new Error(
                  `${displayName}.Consumer must be rendered within a ${displayName}.Provider`
                )
              return children(props)
            }}
          </ctx.Consumer>
        )
      }
    },
  }
}

export default createStore
