const updateProgram = {
  method: 'put',
  path: '/institute/:instituteId/program',
  transformBody: ({ instituteId: institute, ...rest }) => ({
    ...rest,
    institute,
  }),
}

export default updateProgram
