import { formatDateTime } from 'systems/Core'

const formatDateTimeRange = (
  dateTimeStart,
  dateTimeEnd,
  size,
  separator = ' - '
) =>
  formatDateTime(dateTimeStart, size) +
  separator +
  formatDateTime(dateTimeEnd, size)

export default formatDateTimeRange
