import React, { useContext } from 'react'
import { Match, navigate } from '@reach/router'

import Select from 'components/Select'
import { UserContext } from 'systems/Auth'
import { Row, InBetweenSpacing, Heading, Button } from 'systems/Core'

import Room from './Room'
import TelecomContext from './TelecomContext'
import { useGlobalMachine } from 'utils/createGlobalMachine'
import { queueMachine } from '_v3/core/widgets/NavBarWidget/CallQueueList'

const matchTransferring = (state) => (rest = '') => {
  return (
    state.matches(`outgoing.connected.transferring${rest}`) ||
    state.matches(`incoming.connected.transferring${rest}`)
  )
}

const Transfer = () => {
  const user = useContext(UserContext)
  const { state, send } = useContext(TelecomContext)
  const matches = matchTransferring(state)

  const idle = matches('.idle')
  const fetchingUsers = matches('.fetchingUsers.pending')
  const failure = matches('.fetchingUsers.failure')

  const submitting = matches('.submitting.pending')
  const submitSuccess = matches('.submitting.success')
  const submitFailure = matches('.submitting.failure')

  if (failure) {
    return (
      <InBetweenSpacing size="md">
        <p style={{ margin: 0 }}>Failed to load users.</p>
        <Row justifyContent="center">
          <InBetweenSpacing size="sm">
            <Button intent="primary" size="xs" onClick={() => send('RETRY')}>
              Retry
            </Button>
            <Button
              intent="secondary"
              size="xs"
              onClick={() => send('CANCEL_TRANSFER')}
            >
              Cancel
            </Button>
          </InBetweenSpacing>
        </Row>
      </InBetweenSpacing>
    )
  }

  if (fetchingUsers || idle || submitting) {
    return (
      <InBetweenSpacing size="md">
        <Select
          options={state.context.users
            .filter((u) => u.originalId !== user.state.context.id)
            .map((user) => ({
              label: user.name,
              value: user.id,
            }))}
          value={state.context.userId}
          placeholder="Transfer to..."
          noResultsText="No users online"
          isDisabled={fetchingUsers || submitting}
          onChange={(userId) => send('SET_USER', { userId })}
        />
        <Row justifyContent="center">
          <InBetweenSpacing size="sm">
            <Button
              intent="primary"
              size="xs"
              loading={fetchingUsers || submitting}
              disabled={!state.context.userId}
              onClick={() => send('SUBMIT')}
            >
              Submit
            </Button>
            <Button
              intent="secondary"
              size="xs"
              disabled={submitting}
              onClick={() => send('CANCEL_TRANSFER')}
            >
              Cancel
            </Button>
          </InBetweenSpacing>
        </Row>
      </InBetweenSpacing>
    )
  }

  if (submitSuccess) {
    return <p style={{ margin: 0 }}>Call transferred successfully.</p>
  }

  if (submitFailure) {
    return (
      <InBetweenSpacing size="md">
        <p style={{ margin: 0 }}>Failed to transfer call.</p>
        <Row justifyContent="center">
          <InBetweenSpacing size="sm">
            <Button intent="primary" size="xs" onClick={() => send('RETRY')}>
              Retry
            </Button>
            <Button
              intent="secondary"
              size="xs"
              onClick={() => send('CANCEL_TRANSFER')}
            >
              Cancel
            </Button>
          </InBetweenSpacing>
        </Row>
      </InBetweenSpacing>
    )
  }

  return null
}

const Call = ({
  callerName,
  error,
  enableEventAssignment,
  eventId,
  onAccept,
  onReject,
  onTransfer,
  onDisconnect,
  onRetry,
  onDismiss,
  children,
}) => {
  const { state } = useContext(TelecomContext)
  const [queueState, queueSend] = useGlobalMachine(queueMachine)
  const relation = state.context.caller ? state.context.caller.relation : null
  const matches = matchTransferring(state)

  function setWorkerBusy() {
    if (queueState.context.activity !== 'Busy') {
      queueSend('UPDATE_WORKER_ACTIVITY', { activity: 'Busy' })
    }
  }

  function setWorkerIdle() {
    if (queueState.context.activity !== 'Idle') {
      queueSend('UPDATE_WORKER_ACTIVITY', { activity: 'Idle' })
    }
  }

  function handleAccept() {
    onAccept()
    setWorkerBusy()
  }

  function handleReject() {
    onReject()
    setWorkerIdle()
  }

  function handleDisconnect() {
    onDisconnect()
    setWorkerIdle()
  }

  if (matches()) {
    return <Transfer />
  }

  return (
    <Match path="/calls/assign-event">
      {(props) => (
        <InBetweenSpacing size="md">
          {callerName && <Heading size="xs">{callerName}</Heading>}
          {relation && (
            <p>
              <strong>Relation to Patient:</strong> {relation.name}
            </p>
          )}
          {error && <p style={{ margin: 0 }}>{error}</p>}
          {(state.matches('incoming.connected') ||
            state.matches('outgoing.connected')) && (
            <Room
              name={
                (state.context.caller && state.context.caller.callId) ||
                state.context.callId
              }
            />
          )}
          {children}
          <Row justifyContent="center">
            <InBetweenSpacing size="sm">
              {onAccept && (
                <Button intent="success" size="xs" onClick={handleAccept}>
                  Accept
                </Button>
              )}
              {onReject && (
                <Button intent="danger" size="xs" onClick={handleReject}>
                  Reject
                </Button>
              )}
              {enableEventAssignment && eventId ? (
                <Button
                  intent="success"
                  size="xs"
                  onClick={() => navigate(`/events/${eventId}`)}
                >
                  Go to Event
                </Button>
              ) : (
                enableEventAssignment &&
                !props.match && (
                  <Button
                    intent="success"
                    size="xs"
                    onClick={() => navigate('/calls/assign-event')}
                  >
                    Assign Event
                  </Button>
                )
              )}
              {onTransfer && (
                <Button intent="primary" size="xs" onClick={onTransfer}>
                  Transfer
                </Button>
              )}
              {onDisconnect && (
                <Button intent="danger" size="xs" onClick={handleDisconnect}>
                  Hang Up
                </Button>
              )}
              {onRetry && (
                <Button intent="primary" size="xs" onClick={onRetry}>
                  Retry
                </Button>
              )}
              {onDismiss && (
                <Button intent="secondary" size="xs" onClick={onDismiss}>
                  Dismiss
                </Button>
              )}
            </InBetweenSpacing>
          </Row>
        </InBetweenSpacing>
      )}
    </Match>
  )
}

export default Call
