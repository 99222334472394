import axios from 'axios'

import { getBody } from 'systems/Request'
import { getHeaders } from 'systems/Request'
import { getPath } from 'systems/Request'
import { getQuery } from 'systems/Request'
import { getRequest } from 'systems/Request'
import logout from 'utils/logout'

const client = axios.create()

function isStatus(err, num) {
  return err.response && err.response.status === num
}

export const requestWithRetry = async (request, params, retry = 0) => {
  const {
    method,
    responseType,
    transformData = (data) => data,
    transformError = ({ error }) => error,
  } = getRequest(request)

  let parsedPath = null
  try {
    parsedPath = getPath(request, params)
  } catch (error) {
    throw error
  }

  try {
    const queryParams = getQuery(request, params)
    const response = await client.request({
      method,
      url: `${process.env.REACT_APP_API_URL.slice(0, -1)}${parsedPath}/`,
      params: queryParams,
      headers: getHeaders(),
      data: getBody(request, params),
      responseType,
    })

    return transformData(response.data)
  } catch (error) {
    const tError = transformError({ error, params })

    // 400 errors should skip the retry system
    if (isStatus(tError, 400)) {
      throw tError
    }

    if (request === 'getUser' && isStatus(tError, 401)) {
      logout()
      return tError || new Error('Unauthorized')
    }

    // When API returns 404, it means the resource doesn't exists.
    // In that case we need to return null to be able to either dispatch a
    // POST or PUT/PATCH request
    if (isStatus(tError, 404) || isStatus(tError, 403)) {
      return tError || null
    }

    if (retry <= 4) {
      const nextRetry = retry + 1
      console.log(`retrying ${parsedPath} request (${nextRetry})...`)
      return requestWithRetry(request, params, nextRetry)
    }

    return tError
  }
}
