import * as R from 'ramda'
import { Machine, assign } from 'xstate'
import { schema, normalize } from 'normalizr'

import { dispatchRequest } from 'systems/Request'

// Factories

export const createUser = ({ id, name = 'Unknown', online = false } = {}) => ({
  id,
  name,
  online,
})

export const createChannel = ({ id, uid, name = '' } = {}) => ({
  id,
  uid,
  name,
})

export const createMessage = ({
  id,
  userId,
  channelId,
  timestamp = Date.now(),
  content = '',
} = {}) => ({
  id,
  userId,
  channelId,
  timestamp,
  content,
})

// Schemas

export const userSchema = new schema.Entity(
  'users',
  {},
  {
    idAttribute: ({ attributes: { id } }) => id,
    processStrategy: ({ friendlyName: name, online, attributes: { id } }) =>
      createUser({
        id,
        name,
        online,
      }),
  }
)

export const channelSchema = new schema.Entity(
  'channels',
  {},
  {
    idAttribute: 'sid',
    processStrategy: ({ sid: id, uniqueName: uid, friendlyName: name }) =>
      createChannel({
        id,
        uid,
        name,
      }),
  }
)

export const messageSchema = new schema.Entity(
  'messages',
  {},
  {
    idAttribute: 'sid',
    processStrategy: ({
      sid: id,
      channel: { sid: channelId },
      timestamp,
      body: content,
      attributes: { userId },
    }) =>
      createMessage({
        id,
        userId,
        channelId,
        timestamp,
        content,
      }),
  }
)

// Selectors

export const getCurrentUserId = () => (state) => state.context.user.id

export const getUsers = () => (state) =>
  Object.values({ ...state.context.entities.users })

export const getChannels = () => (state) =>
  Object.values({ ...state.context.entities.channels })

export const getUser = (userId) => (state) =>
  state.context.entities.users[userId]

export const getChannel = (channelId) => (state) =>
  state.context.entities.channels[channelId]

export const getActiveChannel = () => (state) =>
  state.context.channelId ? getChannel(state.context.channelId)(state) : null

export const getActiveChannelMessages = () => (state) =>
  state.context.messageIds.map((messageId) => getMessage(messageId)(state))

export const getMessage = (messageId) => (state) =>
  state.context.entities.messages[messageId]

// Guards

export const isMessageValid = (context) => context.message.length > 0

const chatUsers = ['advisor', 'manager', 'coordinator']

export const canOpenChat = (context) => {
  const roles = R.pathOr([], ['user', 'roles'], context)
  return roles.some((role) => chatUsers.includes(role))
}

// Actions

export const setUser = assign({ user: (_, event) => event.data })

export const setToken = assign({ token: (_, event) => event.data })

export const setClient = assign({ client: (_, event) => event.data })

export const mergeEntities = assign({
  entities: (context, event) =>
    R.mergeDeepRight(context.entities, event.data.entities),
})

export const setChannel = assign({
  channelId: (context) =>
    context.entities.channels
      ? Object.keys(context.entities.channels)[0]
      : null,
})

export const setMessageIds = assign({
  messageIds: (_, event) => event.data.result,
})

export const appendMessageId = assign({
  messageIds: (context, event) => [...context.messageIds, event.data.result],
})

export const updateMessage = assign({ message: (_, event) => event.value })

export const resetMessage = assign({ message: '' })

// Services

export const loadUser = () => dispatchRequest('getUser')

export const loadToken = () =>
  dispatchRequest('getMediaToken').then((tokens) => {
    localStorage.setItem('video-token', tokens.video_token)
    return tokens.video_token
  })

const TwilioClient = R.path(['Twilio', 'Chat', 'Client'], window)

export const initializeClient = (context) => {
  if (!TwilioClient) return Promise.reject()
  return TwilioClient.create(context.token).then((client) => {
    window.chatClient = client
    return client
  })
}

export const connect = (context) => (callback) => {
  context.client.on('connectionStateChanged', (state) => {
    switch (state) {
      case 'connecting':
        return

      case 'connected':
        return callback('CONNECTED')

      case 'disconnecting':
        return

      case 'disconnected':
        return callback('DISCONNECTED')

      case 'denied':
        return callback('DENIED')

      default:
        return
    }
  })

  return () => context.client.removeAllListeners('connectionStateChanged')
}

export const updateUserAttributes = (context) =>
  context.client.user.updateFriendlyName(context.user.fullName).then(() =>
    context.client.user.updateAttributes({
      id: context.user.id,
      identity: context.client.user.identity,
      online: true,
      web: true,
    })
  )

export const loadChannels = (context) =>
  context.client
    .getPublicChannelDescriptors()
    .then((paginator) => paginator.items)
    .then((channels) => {
      if (channels.length === 0) {
        return context.client
          .createChannel({
            uniqueName: 'general',
            friendlyName: 'General',
          })
          .then(() =>
            context.client
              .getPublicChannelDescriptors()
              .then((paginator) => paginator.items)
          )
      }
      return channels
    })
    .then((channels) => normalize(channels, [channelSchema]))

export const loadMembers = async (context) => {
  try {
    await context.client
      .getChannelBySid(context.channelId)
      .then((channel) => channel.join())
  } catch (error) {
    // throw error;
  }

  return context.client.getChannelBySid(context.channelId).then((channel) =>
    channel
      .getMembers()
      .then((members = []) =>
        Promise.all(members.map((member) => member.getUser()))
      )
      // We need to confirm `user.attributes.id` exists
      .then((users = []) =>
        normalize(
          users.filter((user) => user.attributes.id),
          [userSchema]
        )
      )
  )
}

export const loadMessages = (context) =>
  context.client.getChannelBySid(context.channelId).then((channel) =>
    channel
      .getMessages()
      .then((paginator) => paginator.items)
      .then((messages) =>
        // We need to confirm `message.attributes.userId` exists
        normalize(
          messages.filter((message) => message.attributes.userId),
          [messageSchema]
        )
      )
  )

export const watchChannel = (context) => (callback) => {
  context.client.on('userUpdated', (event) => {
    callback({
      type: 'USER_UPDATED',
      data: normalize(event.user, userSchema),
    })
  })

  let _channel

  context.client.getChannelBySid(context.channelId).then((channel) => {
    _channel = channel

    channel.on('messageAdded', (message) => {
      callback({
        type: 'MESSAGE_ADDED',
        data: normalize(message, messageSchema),
      })
    })
  })

  context.client.on('tokenAboutToExpire', () => {
    dispatchRequest('getMediaToken').then((tokens) => {
      localStorage.setItem('video-token', tokens.video_token)
      context.client.updateToken(tokens.video_token)
    })
  })

  return () => {
    context.client.removeAllListeners('userUpdated')
    _channel.removeAllListeners('messageAdded')
  }
}

export const sendMessage = (context) =>
  context.client
    .getChannelBySid(context.channelId)
    .then((channel) =>
      channel.sendMessage(context.message, { userId: context.user.id })
    )

// Machine

export const machine = Machine(
  {
    id: 'chat',
    context: {
      token: null,
      client: null,
      user: null,
      entities: {},
      channelId: null,
      messageIds: [],
      message: '',
    },
    initial: 'setup',
    states: {
      setup: {
        initial: 'loadingUser',
        states: {
          loadingUser: {
            invoke: {
              src: 'loadUser',
              onDone: {
                target: 'loadingToken',
                actions: 'setUser',
              },
              onError: 'failure',
            },
          },
          loadingToken: {
            invoke: {
              src: 'loadToken',
              onDone: {
                target: 'initializingClient',
                actions: 'setToken',
              },
              onError: 'failure',
            },
          },
          initializingClient: {
            invoke: {
              src: 'initializeClient',
              onDone: {
                target: 'connecting',
                actions: 'setClient',
              },
              onError: 'failure',
            },
          },
          connecting: {
            invoke: {
              src: 'connect',
            },
            initial: 'loading',
            states: {
              loading: {},
              success: {
                type: 'final',
              },
              failure: {},
            },
            on: {
              CONNECTED: '.success',
              DISCONNECTED: '.failure',
              DENIED: '.failure',
            },
            onDone: 'updatingUserAttributes',
          },
          updatingUserAttributes: {
            invoke: {
              src: 'updateUserAttributes',
              onDone: 'checkingPermissions',
              onError: 'failure',
            },
          },
          checkingPermissions: {
            always: [
              {
                target: 'loadingChannels',
                cond: 'canOpenChat',
              },
              {
                target: 'failure',
              },
            ],
          },
          loadingChannels: {
            invoke: {
              src: 'loadChannels',
              onDone: {
                target: 'success',
                actions: 'mergeEntities',
              },
              onError: 'failure',
            },
          },
          success: {
            type: 'final',
          },
          failure: {
            RETRY: 'loadingToken',
          },
        },
        onDone: 'hidden',
      },
      hidden: {
        on: {
          TOGGLE: {
            target: 'visible',
          },
        },
      },
      visible: {
        always: {
          target: 'chatting',
          actions: 'setChannel',
        },
        on: {
          TOGGLE: 'hidden',
        },
      },
      chatting: {
        initial: 'loading',
        states: {
          loading: {
            initial: 'users',
            states: {
              users: {
                invoke: {
                  src: 'loadMembers',
                  onDone: {
                    target: 'messages',
                    actions: 'mergeEntities',
                  },
                  onError: {
                    target: 'failure',
                  },
                },
              },
              messages: {
                invoke: {
                  src: 'loadMessages',
                  onDone: {
                    target: 'success',
                    actions: ['mergeEntities', 'setMessageIds'],
                  },
                  onError: {
                    target: 'failure',
                  },
                },
              },
              success: {
                type: 'final',
              },
              failure: {},
            },
            onDone: 'success',
          },
          success: {
            invoke: {
              src: 'watchChannel',
            },
            initial: 'idle',
            states: {
              idle: {},
              sending: {
                invoke: {
                  src: 'sendMessage',
                  onDone: {
                    target: 'idle',
                    actions: 'resetMessage',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
              },
            },
            on: {
              USER_UPDATED: {
                actions: ['mergeEntities'],
              },
              MESSAGE_ADDED: {
                actions: ['mergeEntities', 'appendMessageId'],
              },
            },
          },
          failure: {},
        },
        on: {
          UPDATE_MESSAGE: {
            actions: 'updateMessage',
          },
          SEND_MESSAGE: {
            target: '.success.sending',
            cond: 'isMessageValid',
          },
          TOGGLE: 'hidden',
        },
      },
    },
  },
  {
    guards: {
      isMessageValid,
      canOpenChat,
    },
    actions: {
      setUser,
      setToken,
      setClient,
      mergeEntities,
      setChannel,
      setMessageIds,
      appendMessageId,
      updateMessage,
      resetMessage,
    },
    services: {
      loadUser,
      loadToken,
      initializeClient,
      connect,
      updateUserAttributes,
      loadChannels,
      loadMembers,
      loadMessages,
      watchChannel,
      sendMessage,
    },
  }
)
