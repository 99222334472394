import React from 'react'
import PropTypes from 'prop-types'
import COLORS from 'constants/COLORS'
import View from 'components/View'
import Spinner from 'components/Spinner'
import Icon from 'components/Icon'
import { navigate } from '@reach/router'

const backgroundColorsByIntent = {
  primary: COLORS.blue2,
  secondary: COLORS.gray5,
  success: COLORS.green2,
  danger: COLORS.red2,
}

const hoverBackgroundColorsByIntent = {
  primary: COLORS.blue1,
  secondary: COLORS.gray4,
  success: COLORS.green1,
  danger: COLORS.red1,
}

const colorsByIntent = {
  primary: COLORS.white,
  secondary: COLORS.darkGray2,
  success: COLORS.white,
  danger: COLORS.white,
}

const Button = ({
  className,
  type,
  intent,
  leftIcon,
  rightIcon,
  path,
  isFullWidth,
  isLoading,
  isDisabled,
  onClick,
  children,
  ...rest
}) => (
  <View
    className={className}
    component="button"
    props={{
      type,
      disabled: isLoading || isDisabled,
      onClick: (event) => {
        if (type !== 'submit') {
          event.preventDefault()
        }
        path && navigate(path)
        onClick()
      },
    }}
    border="none"
    borderRadius={intent ? 3 : null}
    backgroundColor={intent ? backgroundColorsByIntent[intent] : 'transparent'}
    hoverBackgroundColor={
      intent && !isLoading && !isDisabled
        ? hoverBackgroundColorsByIntent[intent]
        : null
    }
    color={intent ? colorsByIntent[intent] : COLORS.darkGray2}
    hoverColor={isLoading || isDisabled ? null : intent ? null : COLORS.blue1}
    fontSize={14}
    display="inline-flex"
    justifyContent="center"
    alignItems="center"
    width={isFullWidth ? '100%' : null}
    paddingTop={9}
    paddingBottom={9}
    paddingLeft={12}
    paddingRight={12}
    opacity={isLoading || isDisabled ? 0.8 : null}
    cursor={isLoading || isDisabled ? 'default' : 'pointer'}
    {...rest}
  >
    {isLoading && <Spinner />}
    {!isLoading && leftIcon && (
      <Icon name={leftIcon} size={2} marginRight={Boolean(children)} />
    )}
    {!isLoading && children}
    {!isLoading && rightIcon && (
      <Icon name={rightIcon} size={2} marginLeft={Boolean(children)} />
    )}
  </View>
)

Button.propTypes = {
  intent: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
  leftIcon: PropTypes.string,
  path: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

Button.defaultProps = {
  onClick: () => {},
}

export default Button
