import 'styled-components/macro'
import React from 'react'

import View from 'components/View'
import { theme } from 'themeUtils'

const Text = ({
  intent = 'dark',
  size = 3,
  align,
  children,
  isBreak = true,
  ...rest
}) => (
  <View
    {...rest}
    component="span"
    css={`
      color: ${{
        secondary: theme('colors.gray.7'),
        tertiary: theme('colors.gray.6'),
        success: theme('colors.green.0'),
        light: theme('colors.white'),
        dark: theme('colors.black'),
      }[intent]};
      font-family: ${theme('fontFamilies.sans')};
      font-size: ${theme('fontSizes.' + size)};
      text-align: ${align};
      line-height: 1.35;
      ${isBreak && `word-break: break-all;`};
    `}
  >
    {children}
  </View>
)

export default Text
