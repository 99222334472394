import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Block } from 'jsxstyle'
import dayjs from 'dayjs'
import Markdown from 'react-markdown'

import logout from 'utils/logout'
import Grid, { Col } from 'components/Grid'
import Backdrop from 'components/Backdrop'
import Button from 'components/Button'
import Card from 'components/Card'
import Spinner from 'components/Spinner'

import { fetch, handleError } from 'systems/Request'

const TERMS_AND_CONDITIONS_TEXT = `This Patient Usage Policy covers the security and use of the 4D Healthware platform.

This policy applies to any individual using the 4D Healthware platform

### Computer Access Control – Individual’s Responsibility

Access to the 4D Healthware Platform is controlled by the use of User IDs, passwords. All User IDs and passwords are
to be uniquely created by the patient and/or caregiver, and consequently, these individuals are accountable for all
actions on the (4D Healthware) platform.

### Individuals must not:
- Allow anyone else to use their user ID and password for the (4D Healthware) platform
- Leave their user accounts logged in at an unattended and unlocked computer.
- Use someone else’s user ID and password to access their information (4D Healthware’s) platform without their permission
- Leave their password unprotected (for example writing it down).
- Perform any unauthorized changes to (4D Healthware’s) platform or information.
- Attempt to access data that they are not authorized to use or access.
- Connect any non-(4D Healthware) authorized device to the (4D Healthware) cloud based solution
- Give or transfer (4D Healthware) data or software to any person or organization outside (4D Healthware) without the authority of (4D Healthware).
- Use the cloud based solution for the purposes of harassment or abuse.
- Use profanity, obscenities, or derogatory remarks in communications.
- Access, download, send or receive any data (including images), which (4D Healthware) considers offensive in any way, including sexually explicit, discriminatory, defamatory or libelous material.
- Place any information on the Internet that relates to (4D Healthware), alter any information about it, or express any opinion about (4D Healthware), unless they are specifically authorized to do this.
- Send unprotected sensitive or confidential information externally.
- Forward (4D Healthware) proprietary or patient data and/or information (non-4D Healthware) email accounts or data solutions or IT systems
- In any way infringe any copyright, database rights, trademarks or other intellectual property.
- Connect (4D Healthware) devices to the internet using non-standard connections.

### Telephone (Voice and Video) Cloud Based Solution Conditions of Use
Use of (4D Healthware) Platform is intended for business use. Individuals must not use (4D Healthware’s) voice and video cloud based solution for sending or receiving private communications on personal matters.
All non-urgent personal communications should be made at an individual’s own expense using alternative means of communications`

const TermsAndConditions = () => {
  const [user, setUser] = useState(null)

  // TODO: necessary because of react query isn't leading
  // well with query cache when modal is mounting
  const [getUser, { status: userStatus }] = useMutation(fetch('getUser'), {
    onSuccess: setUser,
  })

  const [updateUserProfile, { status: updateStatus }] = useMutation(
    (variables) => {
      return fetch('updateUserProfile')({
        ...variables,
        username: R.prop('username', user),
      })
    },
    { onError: handleError }
  )

  const isLoading = userStatus === 'loading' || updateStatus === 'loading'
  const updateSuccess = updateStatus === 'success'

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || R.prop('termsAndConditions', user) || updateSuccess) {
    return null
  }

  return (
    <Backdrop>
      <Card title="Terms, Conditions and Usage Policy" isTitleSmall={true}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Grid>
            <Col>
              <Block textAlign="justify" maxHeight={300} overflow="auto">
                <Markdown
                  source={TERMS_AND_CONDITIONS_TEXT}
                  renderers={{
                    root: ({ children }) => (
                      <div id="terms-and-conditions">{children}</div>
                    ),
                  }}
                />
              </Block>
            </Col>
            <Col>
              <Button
                intent="primary"
                onClick={() =>
                  updateUserProfile({
                    termsAndConditions: dayjs().utc().format(),
                  })
                }
              >
                Agree
              </Button>{' '}
              <Button intent="danger" onClick={logout}>
                Disagree
              </Button>
            </Col>
          </Grid>
        )}
      </Card>
    </Backdrop>
  )
}

export default TermsAndConditions
