import React, { Suspense, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { ReactQueryConfigProvider } from 'react-query'

import ThemeStore from '_v3/core/stores/ThemeStore'
import AuthStore from '_v3/core/stores/SessionStore'
import EntityStore from '_v3/core/stores/EntityStore'

import theme from 'theme'
import Routes from 'components/Routes'
import Spinner from 'components/Spinner'
import { useSessionService } from 'hooks/useSessionService'

const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <Spinner size="lg" />
  </div>
)

const queryConfig = {
  retry: 5,
  refetchOnWindowFocus: false,
}

const Content = () => {
  const { loginWithToken } = useSessionService()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        await loginWithToken()
      } catch (error) {
        //
      } finally {
        setIsReady(true)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isReady ? <Routes /> : <Loading />
}

const App = () => (
  <ReactQueryConfigProvider config={queryConfig}>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <ThemeStore.Provider>
          <AuthStore.Provider>
            <EntityStore.Provider>
              <Content />
            </EntityStore.Provider>
          </AuthStore.Provider>
        </ThemeStore.Provider>
      </Suspense>
    </ThemeProvider>
  </ReactQueryConfigProvider>
)

export default App
