import * as R from 'ramda'
import React from 'react'
import { RiEyeOffLine, RiEyeLine } from 'react-icons/ri'
import { Button } from '@blueprintjs/core'
import { useQuery } from 'react-query'
import { Machine, assign } from 'xstate'
import styled from 'styled-components'

import {
  createGlobalMachine,
  useGlobalMachine,
} from 'utils/createGlobalMachine'

import COLORS from 'constants/COLORS'
import View from 'components/View'
import { fetch } from 'systems/Request'
import { ReservationList } from './ReservationList'

const Worker = R.path(['Twilio', 'TaskRouter', 'Worker'], window)

const getActivities = (ctx) =>
  new Promise((resolve, reject) => {
    if (!ctx.worker) reject()
    ctx.worker.activities.fetch(function (_error, activityList = []) {
      const list = R.propOr([], 'data', activityList)
      const reducer = (obj, item) => R.assoc(item.friendlyName, item.sid, obj)
      resolve(list.reduce(reducer, {}))
    })
  })

const getReservations = (ctx) =>
  new Promise((resolve, reject) => {
    if (!ctx.worker) reject()
    ctx.worker.fetchReservations(function (_error, reservations) {
      resolve(R.propOr([], 'data', reservations))
    }, {})
  })

const updateWorkerActivity = (ctx, ev) => {
  if (!ctx.worker) return
  const next = R.prop(ev.activity, ctx.activities)
  next && ctx.worker.update({ ActivitySid: next })
}

const setActivity = assign({
  activity: (_, ev) => ev.worker.activityName,
})

const machine = Machine({
  id: 'queue',
  initial: 'idle',
  context: {
    token: null,
    worker: null,
    activity: null,
    activities: [],
    reservations: [],
  },
  states: {
    idle: {
      on: {
        ADD_WORKER: [
          {
            target: 'idle',
            cond: () => !Boolean(Worker),
          },
          {
            target: 'getResources',
            actions: [
              assign({ token: (_, ev) => ev.token }),
              assign({ worker: (_, ev) => new Worker(ev.token) }),
            ],
          },
        ],
      },
    },
    getResources: {
      invoke: {
        onDone: {
          target: 'connect',
          actions: [
            assign({ activities: (_, ev) => ev.data[0] }),
            assign({ reservations: (_, ev) => ev.data[1] }),
          ],
        },
        src: (ctx) => {
          return Promise.all([getActivities(ctx), getReservations(ctx)])
        },
      },
    },
    connect: {
      on: {
        READY: {
          actions: [setActivity],
        },
        SET_ACTIVITY: {
          actions: [setActivity],
        },
        UPDATE_WORKER_ACTIVITY: {
          actions: (ctx, ev) => {
            if (ctx.activity !== ev.activity) {
              updateWorkerActivity(ctx, ev)
            }
          },
        },
        WORKER_ONLINE: {
          actions: (ctx) => {
            updateWorkerActivity(ctx, { activity: 'Idle' })
          },
        },
        WORKER_OFFLINE: {
          actions: (ctx, ev) => {
            updateWorkerActivity(ctx, { activity: 'Offline' })
            if (typeof ev.onAfterOffline === 'function') {
              ev.onAfterOffline()
            }
          },
        },
      },
      invoke: {
        src: ({ worker }) => (send) => {
          worker
            .on('ready', function (worker) {
              send({ type: 'READY', worker })
            })
            .on('activity.update', function (worker) {
              send({ type: 'SET_ACTIVITY', worker })
            })
            .on('reservation.accepted', function () {
              send({
                type: 'UPDATE_WORKER_ACTIVITY',
                activity: 'Busy',
              })
            })
            .on('reservation.wrapup', function () {
              send({
                type: 'UPDATE_WORKER_ACTIVITY',
                activity: 'Idle',
              })
            })
        },
      },
    },
  },
})

export const queueMachine = createGlobalMachine(machine)

const StatusButton = styled(Button)`
  .bp3-button-text > span {
    color: ${COLORS.darkGray3};
  }
`

export const CallQueueList = ({ showsActivity }) => {
  const [state, send] = useGlobalMachine(queueMachine)
  const { context: ctx } = state

  function handleChangeActivity(activity) {
    send('UPDATE_WORKER_ACTIVITY', { activity })
  }

  useQuery('getMediaToken', fetch('getMediaToken'), {
    onSuccess: async (res) => {
      send('ADD_WORKER', { token: res.workerToken })
    },
  })

  if (!showsActivity) return null
  return (
    <View display="flex" alignItems="center" marginRight={15}>
      {ctx.activity === 'Offline' && (
        <StatusButton
          minimal
          outlined
          onClick={() => handleChangeActivity('Idle')}
          rightIcon={<RiEyeOffLine size={20} color={COLORS.red3} />}
        >
          <span>Status:</span> Offline
        </StatusButton>
      )}
      {ctx.activity === 'Idle' && (
        <StatusButton
          minimal
          outlined
          onClick={() => handleChangeActivity('Offline')}
          rightIcon={<RiEyeLine size={20} color={COLORS.green3} />}
        >
          <span>Status:</span> Available
        </StatusButton>
      )}
      {ctx.activity === 'Busy' && (
        <StatusButton
          minimal
          outlined
          onClick={() => handleChangeActivity('Offline')}
          rightIcon={<RiEyeLine size={20} color={COLORS.orange1} />}
        >
          <span>Status:</span> Busy
        </StatusButton>
      )}
      <ReservationList reservations={ctx.reservations} />
    </View>
  )
}
